import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthService } from '../../services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { LanguageService } from 'src/app/dynamic/language.service';
import { emptyContactProfile, IContactProfile } from './company.types';
import { COPY, GlobalHelper, goToExternalLink } from '../../global.helper';
import { CommunicationService } from '../../services/communication.service';
import { emptyLinkedInScrappedData, ILinkedInScrappedData } from '../../global.types';
import { ICompanyProfile, ILinkedInPayload } from '../../services/common.service.types';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.less', '../contact/contact.component.less'],
})
export class CompanyComponent implements OnInit, OnDestroy {

  linkedInScrappedData: ILinkedInScrappedData = COPY(emptyLinkedInScrappedData);
  private subscription: Subscription = new Subscription();

  @ViewChild('cntListSelect') cntListSelect!: NgSelectComponent;
  @ViewChild('cmpListSelect') cmpListSelect!: NgSelectComponent;

  contactProfile: IContactProfile = COPY(emptyContactProfile);

  isLoaded: boolean = false;
  isCmpListAdded: boolean = false;
  isCompanyAvailable: boolean = false;

  isCntListAdded: boolean[] = new Array(this.globalHelper.employeeData.cntData.length).fill(false);

  constructor(
    public ls: LanguageService,
    private authService: AuthService,
    public globalHelper: GlobalHelper,
    public commonService: CommonService,
    public communicationService: CommunicationService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.communicationService.linkedInScrappedData$.subscribe((data: ILinkedInScrappedData) => {
        this.linkedInScrappedData = data;
      }),
    );
    if (this.authService.isLoggedIn && !this.authService.planExpired) {
      this.initMethods();
      this.getLinkedInProfileData();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initMethods() {
    this.globalHelper.getDefaultCntList();
    this.globalHelper.getDefaultCmpList();
    this.globalHelper.getDefaultList();
  }

  formatUrl(url: string): string {
    let formattedUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');
    if (formattedUrl.startsWith('linkedin.com/company/')) {
      formattedUrl = formattedUrl.split('/')
        .slice(0, 3)
        .join('/');
    } else {
      formattedUrl = formattedUrl.split('/')[0];
    }
    return formattedUrl;
  }

  getLinkedInProfileData() {
    this.isLoaded = true;
    const {
      tabUrl,
      urlType,
      contact,
      company,
      linkedinHTML,
    } = this.linkedInScrappedData;
    const isLinkedInUrl = tabUrl.includes('linkedin.com');
    const request: ILinkedInPayload = {
      linkedIn: urlType === 'contact' ? this.globalHelper.pDetails.company_li_url : this.formatUrl(tabUrl),
      isView: false,
      fullName: '',
      homeLocation: '',
      companyName: isLinkedInUrl ? contact?.experience?.[0]?.companyName || company?.companyName || '' : '',
      jobTitle: '',
      workLocation: '',
      workDuration: '',
      experience: [],
      cmpLiUrl: isLinkedInUrl ? tabUrl : '',
      cmpLogoUrl: isLinkedInUrl ? company?.companyLogo || '' : '',
      contactLogo: '',
      contactLiUrl: isLinkedInUrl ? tabUrl : '',
      linkedInHTML: isLinkedInUrl ? linkedinHTML || '' : '',
      isCmpUrl: isLinkedInUrl,
      isContact: false,
      isLinkedinUrl: isLinkedInUrl,
      chromeExtCredits: true,
    };
    if (!request.companyName && isLinkedInUrl) {
      return;
    }
    this.commonService.getCompanyProfile(request)
      .then(async(profileData: ICompanyProfile) => {
        if (!profileData) {
          return;
        }
        this.isCompanyAvailable = true;
        const companyLogo: any = await this.globalHelper.getCompanyLogos(profileData.domainId) ||
          this.linkedInScrappedData?.company?.companyLogo;
        this.globalHelper.pDetails = {
          ...this.globalHelper.pDetails,
          company_name: this.getCompanyName(profileData, this.linkedInScrappedData?.company?.companyName),
          companyId: profileData.companyId || '',
          parentDept: profileData.parentDept || 'Not Available',
          emp_range: profileData.empRange || 'Not Available',
          rev_range: profileData.revRange || 'Not Available',
          hq_location: profileData.hqLocation || 'Not Available',
          company_li_url: profileData.companyLiUrl || '',
          company_facebook_url: profileData.companyFacebookUrl || '',
          cmpLogo: companyLogo,
          isSubExpire: profileData.isSubExpire || '',
          website: profileData.website || 'Not Available',
          specialty: Array.from(new Set(profileData.specialty)) || 'Not Available',
          founded: profileData.founded || 'Not Available',
          company_description: profileData.companyDescription || '',
        };
        if (profileData.companyId) {
          await this.fetchCompanyDetails(profileData.companyId);
        }
      })
      .catch((error: any) => {
        this.globalHelper.toastMessages(this.ls.takeErrorMsg(error?.error?.langCode), 'error');
      })
      .finally(() => {
        this.isLoaded = false;
      });
  }

  getCompanyName(profileData: ICompanyProfile, linkedInCompanyName: string) {
    return (profileData.companyName && linkedInCompanyName)
      ? (profileData.companyName === linkedInCompanyName ? profileData.companyName : linkedInCompanyName)
      : profileData.companyName;
  }

  async fetchCompanyDetails(companyId: string) {
    this.globalHelper.getCmpList(companyId);
    this.globalHelper.getListSave('company', companyId);
    await this.globalHelper.getEmployeeList(0, 5);
  }

  onListCancel(type: 'contact' | 'company', index?: any) {
    this.isCntListAdded[index] = !this.isCntListAdded[index];
    const selectModel = type === 'contact' ? this.cntListSelect : this.cmpListSelect;
    if (selectModel) {
      selectModel.clearModel();
    }
    if (type === 'company') {
      this.isCmpListAdded = false;
    }
  }

  saveContactList(saveCntList: string[], index: number, contactId: string, companyId: string) {
    const isDefaultListSelected = this.globalHelper.defaultList.defaultSelectedCntList !== undefined;
    this.isCntListAdded[index] = isDefaultListSelected ? false : !this.isCntListAdded[index];
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
    if (saveCntList.length) {
      const body = {
        'indexIds': [contactId],
        'moduleType': 'mylist',
        'type': 'contact',
        'listName': saveCntList,
      };
      this.commonService.saveCntList(body)
        .then(() => {
          this.globalHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          if (companyId) {
            this.globalHelper.getEmployeeList(0, 5)
              .then();
          }
        })
        .catch(() => {
          this.globalHelper.toastMessages(this.ls.data.contact.contactAlreadyExist, 'error');
        })
        .finally(() => {
          this.onListCancel('contact');
        });
    }
  }

  saveCompanyList(saveCmpList: string[]) {
    this.isCmpListAdded = false;
    if (saveCmpList.length) {
      const body = {
        indexIds: [this.globalHelper.pDetails.companyId],
        moduleType: 'mylist',
        type: 'account',
        listName: saveCmpList,
      };

      this.commonService.saveCmpList(body)
        .then(() => {
          this.globalHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          this.globalHelper.getListSave('company', this.globalHelper.pDetails.companyId);
        })
        .catch(() => {
          this.globalHelper.toastMessages(this.ls.data.company.companyAlreadyExist, 'error');
        })
        .finally(() => {
          this.onListCancel('company');
        });
    }

    this.cmpListSelect?.clearModel();
  }

  viewContact(contactId: string, toggleCollapse: boolean = false) {
    const handleData = (contactData: IContactProfile) => {
      const previousContactId = this.contactProfile?.contactId;
      this.contactProfile = contactData;
      if (previousContactId && previousContactId !== contactId) {
        const previousContact = this.globalHelper.employeeData.cntData.find((contact: any) => contact.contactId === previousContactId);
        if (previousContact) {
          previousContact.isContactCollapse = true;
          previousContact.alreadyView = true;
        }
      }
      this.globalHelper.employeeData.cntData.forEach((contact: any) => {
        contact.isContactCollapse = toggleCollapse ? contact.contactId === contactId ?
          !contact.isContactCollapse : false : contact.contactId === contactId;
        contact.alreadyView = contact.contactId === contactId || contact.alreadyView;
      });
    };
    const handleError = (error: any) => {
      this.globalHelper.toastMessages(this.ls.takeErrorMsg(error?.error?.langCode), 'error');
    };
    this.commonService.contactView(contactId)
      .then(handleData)
      .catch(handleError);
  }

  addToCmpList() {
    this.globalHelper.getCmpList(this.globalHelper.pDetails.companyId);
    const defaultCmpList = this.globalHelper.defaultList.defaultSelectedCmpList;
    if (defaultCmpList !== undefined) {
      if (this.globalHelper.pluginSettings.askWhereToSave) {
        this.isCmpListAdded = !this.isCmpListAdded;
        this.globalHelper.selectedListType.selectedCmpList = [defaultCmpList];
      } else {
        this.saveCompanyList([defaultCmpList]);
        this.globalHelper.getListSave('company', this.globalHelper.pDetails.companyId);
      }
    } else {
      this.isCmpListAdded = !this.isCmpListAdded;
    }

    if (this.cmpListSelect) {
      this.cmpListSelect.clearModel();
    }
  }

  addToCntList(contactId: string, index: number, companyId: string): void {
    this.globalHelper.getCntList(contactId);
    const { defaultSelectedCntList } = this.globalHelper.defaultList;
    const { askWhereToSave } = this.globalHelper.pluginSettings;
    this.isCntListAdded.fill(false);
    this.isCntListAdded[index] = true;
    if (defaultSelectedCntList !== undefined) {
      if (askWhereToSave) {
        this.globalHelper.selectedListType.selectedCntList = [defaultSelectedCntList];
      } else {
        this.saveContactList([defaultSelectedCntList], index, contactId, companyId);
        this.globalHelper.getEmployeeList(0, 5)
          .then();
      }
    }
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
  }

  protected readonly goToExternalLink = goToExternalLink;
}
