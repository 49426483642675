<div class="main-body">
  <ng-container *ngIf="isCompanyAvailable && !isLoaded">
    <!-- company card -->
    <div class="custom-card">
      <div class="profile-card">
        <div class="saved-tag"
             *ngIf="globalHelper.isSaved.isCmpSaved"
        >
          <img class="vts-svg-green"
               src="assets/images/svg/saved-tag.svg"
               alt=""
          >
        </div>
        <div class="profile-top">
          <div class="profile-logo">
            <img [src]="globalHelper.pDetails.cmpLogo &&
                    globalHelper.pDetails.cmpLogo !== '' ?
                    globalHelper.pDetails.cmpLogo :
                    (linkedInScrappedData.tabUrl.includes('linkedin.com') ?
                    (linkedInScrappedData.contact.experience[0].companyLogo !== '' ?
                    linkedInScrappedData.contact.experience[0].companyLogo :
                    'assets/images/png/company-placeholder.png') :
                    'assets/images/png/company-placeholder.png')"
                 alt=""
            >
          </div>
          <div class="profile-content">
            <div class="profile-info">
              <h6 #CMP
                  class="overflow-text"
                  [tooltip]="CMP.offsetWidth < CMP.scrollWidth ? globalHelper.pDetails.company_name :''"
                  placement="bottom"
              >
                {{ globalHelper.pDetails.company_name }}
              </h6>
              <div class="action">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt=""
                       (click)="globalHelper.goToDetails(globalHelper.pDetails.companyId,'company')"
                  >
                </div>
                <div class="add-to-list">
                  <img class="vts-svg-primary cursor"
                       [src]="!globalHelper.isSaved.isCmpSaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                       alt=""
                       (click)="addToCmpList()"
                       [tooltip]="ls.data.company.toolTip.addToList"
                       placement="top"
                  >
                  <div class="vts-border-theme save-contact"
                       *ngIf="isCmpListAdded"
                  >
                    <div class="form-group">
                      <ng-select #cmpListSelect
                                 bindLabel="value"
                                 bindValue="value"
                                 [addTag]="globalHelper.addList"
                                 [addTagText]="ls.data.company.createNewList"
                                 [items]="globalHelper.cmpListData.cmpListDataAvl"
                                 (change)="globalHelper.onListChange('company')"
                                 [(ngModel)]="globalHelper.selectedListType.selectedCmpList"
                                 [multiple]="true" [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                      ></ng-select>
                    </div>
                    <div class="action-buttons">
                      <span class="vts-theme-bg vts-border-theme fill-btn"
                            (click)="saveCompanyList(globalHelper.selectedListType.selectedCmpList)"
                      >
                        {{ ls.data.global.buttons.save }}
                      </span>
                      <span class="vts-border-theme vts-icon-color"
                            (click)="onListCancel('company')"
                      >
                        {{ ls.data.global.buttons.cancel }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span #PRD
                  class="job-title overflow-text"
                  [tooltip]="PRD.offsetWidth < PRD.scrollWidth ? globalHelper.pDetails.parentDept :''"
                  placement="bottom"
            >
              {{ globalHelper.pDetails.parentDept ? globalHelper.pDetails.parentDept : ls.data.global.misc.notAvailable }}
            </span>
          </div>
        </div>
        <div class="profile-bottom">
          <p class="cmp-desc"
             *ngIf="globalHelper.pDetails.company_description"
          >
            {{
              globalHelper.isExpanded.open ? globalHelper.pDetails.company_description :
                globalHelper.isExpanded.open ? globalHelper.pDetails.company_description :
                  globalHelper.pDetails.company_description.slice(0, 100)
            }}
            <a class="vts-icon-color text-to-expand"
               (click)="globalHelper.toggleDescription()"
               *ngIf="globalHelper.pDetails.company_description !== '' && globalHelper.pDetails.company_description.length > 100"
            >
              {{ globalHelper.isExpanded.open ? ls.data.global.misc.viewLess : ls.data.global.misc.viewMore }}
            </a>
          </p>
        </div>
      </div>
    </div>
    <!-- description -->
    <div class="custom-card">
      <div class="profile-card">
        <div class="cmp-profile-bottom">
          <div class="vts-tag-color panel-title">
            {{ ls.data.company.companyInfo }}
          </div>
          <div class="cmp-details-list">
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/copy.svg"
                     alt=""
                >
              </div>
              <div class="info-desc">
                <span class="vts-icon-color cmp-name"
                      (click)="goToExternalLink(globalHelper.pDetails.website,false)"
                >
                  {{ globalHelper.pDetails.website }}
                </span>
                <span *ngIf="globalHelper.pDetails.company_li_url">
                  |
                  <img src="assets/images/svg/linkedin.svg"
                       alt=""
                       (click)="goToExternalLink(globalHelper.pDetails.company_li_url,false)"
                  >
                </span>
                <span *ngIf="globalHelper.pDetails.company_facebook_url">
                  |
                  <img src="assets/images/svg/facebook.svg"
                       alt=""
                       (click)="goToExternalLink(globalHelper.pDetails.company_facebook_url,false)"
                  >
                </span>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/founded.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.foundIn }}</span>
                <p class="val-count">{{ globalHelper.pDetails.founded }}</p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/headquarter.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.headquarterLocation }}</span>
                <p class="val-count">
                  {{ globalHelper.pDetails.hq_location ? globalHelper.pDetails.hq_location : ls.data.global.misc.notAvailable }}
                </p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/empSize.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                  <span>
                    {{ ls.data.company.employeeSize }}
                  </span>
                <p class="val-count">
                  {{
                    globalHelper.pDetails.emp_range ? globalHelper.pDetails.emp_range :
                      ls.data.global.misc.notAvailable
                  }}
                </p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/revenue.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.annualRevenueRange }}</span>
                <p class="val-count">
                  {{ globalHelper.pDetails.rev_range ? globalHelper.pDetails.rev_range : ls.data.global.misc.notAvailable }}
                </p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/industry.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.industry }}</span>
                <p class="val-count">
                  {{ globalHelper.pDetails.parentDept ? globalHelper.pDetails.parentDept : ls.data.global.misc.notAvailable }}
                </p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/specialities.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.specialities }}</span>
                <div class="chip-sets">
                  <ng-container *ngIf="globalHelper.pDetails.specialty.length">
                    <div class="vts-chipset chip-set"
                         *ngFor="let item of globalHelper.pDetails.specialty"
                    >
                      {{ item }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!globalHelper.pDetails.specialty.length">
                    <p class="val-count">{{ ls.data.global.misc.notAvailable }}</p>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- key employees -->
    <div class="custom-card mb-0">
      <div class="profile-card"
           *ngIf="globalHelper.employeeData.cntData && globalHelper.employeeData.cntData.length > 0"
      >
        <div class="cmp-profile-bottom">
          <div class="card-title">
            <div class="vts-tag-color panel-title">
              {{ ls.data.company.keyEmployees }}
            </div>
            <div class="vts-icon-color link"
                 (click)="globalHelper.toggleFill('employee', true)"
            >
              {{ ls.data.company.showEmployees }}
            </div>
          </div>
          <div class="emp-details-list">
            <div class="emp-list-item"
                 *ngFor="let employee of globalHelper.employeeData.cntData;let index = index"
            >
              <div class="emp-list-top">
                <div class="emp-list-top-left">
                  <div class="employee-logo">
                    <ng-container *ngIf="employee.imageUrl; else initials">
                      <img [src]="employee.imageUrl" alt="">
                    </ng-container>
                    <ng-template #initials>
                      <div class="initials"
                           [ngStyle]="employee.avatarStyle"
                      >
                        {{ globalHelper.getInitials(employee.fullName) }}
                      </div>
                    </ng-template>
                  </div>
                  <div class="employee-info">
                    <div class="employee-title">
                      <h4 #FNM
                          class="vts-icon-color overflow-text"
                          [tooltip]="FNM.offsetWidth < FNM.scrollWidth? employee.fullName :''"
                          placement="bottom"
                          (click)="globalHelper.goToDetails(employee.contactId,'contact')"
                      >
                        {{ employee.fullName }}
                      </h4>
                      <ng-container *ngIf="employee?.contact_li_url">
                        <img class="social-icon"
                             src="assets/images/svg/linkedin.svg"
                             alt=""
                             (click)="goToExternalLink(employee.contact_li_url,false)"
                        >
                      </ng-container>
                      <ng-container *ngIf="employee?.facebook_url">
                        <img class="social-icon"
                             src="assets/images/svg/facebook.svg"
                             alt=""
                             (click)="goToExternalLink(employee.facebook_url,false)"
                        >
                      </ng-container>
                    </div>
                    <p #JTE
                       class="overflow-text"
                       [tooltip]="JTE.offsetWidth < JTE.scrollWidth ? employee.jobTitle :''"
                       placement="bottom"
                    >
                      {{ employee.jobTitle ? employee.jobTitle : "" }}
                    </p>
                  </div>
                </div>
                <div class="emp-list-top-right">
                  <div class="action">
                    <div class="add-to-list">
                      <img class="vts-svg-primary cursor"
                           [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                           alt=""
                           [tooltip]="ls.data.company.toolTip.addToList"
                           placement="top"
                           (click)="addToCntList(employee.contactId, index, employee.companyId)"
                      >
                      <div class="vts-border-theme save-contact"
                           *ngIf="isCntListAdded[index]"
                      >
                        <div class="form-group">
                          <ng-select #cntListSelect
                                     bindLabel="value"
                                     bindValue="value"
                                     [addTag]="globalHelper.addList"
                                     [items]="globalHelper.cntListData.cntListDataAvl"
                                     (change)="globalHelper.onListChange('contact')"
                                     [(ngModel)]="globalHelper.selectedListType.selectedCntList"
                                     [addTagText]="ls.data.company.createNewList" [multiple]="true"
                                     [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                          ></ng-select>
                        </div>
                        <div class="action-buttons">
                          <span class="vts-theme-bg vts-border-theme fill-btn"
                                (click)="saveContactList(globalHelper.selectedListType.selectedCntList, index, employee.contactId,employee.companyId)"
                          >
                            {{ ls.data.global.buttons.save }}
                          </span>
                          <span class="vts-border-theme vts-icon-color"
                                (click)="onListCancel('contact', index)"
                          >
                            {{ ls.data.global.buttons.cancel }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="emp-list-bottom">
                <div class="emp-list-bottom-left">
                  <div class="vts-green-color-border emp-details">
                    <div #item
                         class="contact-details"
                         [ngClass]="{
                            'green-contact': employee.alreadyView && (employee.emailAddress || employee.phoneOffice || employee.phoneDirect),
                            'not-available': !employee.emailAddress && !employee.phoneOffice && !employee.phoneDirect,
                            'disabled': employee.isContactCollapse
                         }"
                         (click)="viewContact(employee.contactId,employee.isContactCollapse)"
                         (mouseenter)="globalHelper.showHover(item)"
                         (mouseleave)="globalHelper.hideHover(item)"
                    >
                      <div class="details-wrapper">
                        <img class="vts-svg-primary"
                             src="assets/images/svg/xd-email.svg"
                             alt=""
                             [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.workEmail : ''"
                             placement="top"
                             [ngClass]="{
                                 'item-not-available': !employee.emailAddress,
                                 'item-green': employee.alreadyView && employee.emailAddress
                             }"
                        >
                        <span class="divider">|</span>
                        <img class="vts-svg-primary"
                             src="assets/images/svg/xd-office-phone.svg"
                             alt=""
                             [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.phoneOffice : ''"
                             placement="top"
                             [ngClass]="{
                                 'item-not-available': !employee.phoneOffice,
                                 'item-green': employee.alreadyView && employee.phoneOffice
                             }"
                        >
                        <span class="divider">|</span>
                        <img class="vts-svg-primary"
                             src="assets/images/svg/xd-cell-phone.svg"
                             alt=""
                             [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.directPhone : ''"
                             placement="top"
                             [ngClass]="{
                               'item-not-available': !employee.phoneDirect,
                               'item-green': employee.alreadyView && employee.phoneDirect
                             }"
                        >
                      </div>
                      <div class="show-details"
                           *ngIf="globalHelper.hoveredItem === item &&
                              (employee.emailAddress || employee.phoneOffice || employee.phoneDirect) &&
                              !employee.alreadyView"
                           [tooltip]="!employee.alreadyView ? ls.data.global.toolTip.thisCostOneCredit : ''"
                           placement="right"
                      >
                        <p>{{ ls.data.actions.showDetails }}</p>
                      </div>
                    </div>
                    <div class="hide-link"
                         *ngIf="employee.isContactCollapse"
                         (click)="viewContact(employee.contactId, employee.isContactCollapse)"
                    >
                      {{ ls.data.actions.hide }}
                    </div>
                  </div>
                  <div class="emp-details-view"
                       *ngIf="employee.isContactCollapse && contactProfile"
                  >
                    <ul>
                      <li [ngClass]="{'na': !contactProfile.emailAddress}">
                        <p class="label">{{ ls.data.company.workEmail }}</p>
                        :
                        <app-user-feedback [attributeValue]="contactProfile.emailAddress"
                                           [contactId]="contactProfile.contactId"
                                           [attributeName]="'email'"
                                           [isContactView]="true"
                                           [isListView]="true"
                        ></app-user-feedback>
                      </li>
                      <li [ngClass]="{'na': !contactProfile.phoneOffice}">
                        <p class="label">{{ ls.data.company.phoneOffice }}</p>
                        :
                        <app-user-feedback [attributeValue]="contactProfile.phoneOffice"
                                           [contactId]="contactProfile.contactId"
                                           [attributeName]="'phoneOffice'"
                                           [isContactView]="true"
                                           [isListView]="true"
                        ></app-user-feedback>
                      </li>
                      <li [ngClass]="{'na': !contactProfile.phoneDirect}">
                        <p class="label">{{ ls.data.company.directPhone }}</p>
                        :
                        <app-user-feedback [attributeValue]="contactProfile.phoneDirect"
                                           [contactId]="contactProfile.contactId"
                                           [attributeName]="'phoneDirect'"
                                           [isContactView]="true"
                                           [isListView]="true"
                        ></app-user-feedback>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="redirect-to-emp text-center">
          <div class="redirect-pointer"
               (click)="globalHelper.goToDetails(globalHelper.employeeData.cntData[0].companyId,'contact')"
          >
            <strong>{{ ls.data.company.moreCompanyDetails }}</strong>
            <img class="vts-svg-primary"
                 src="assets/images/svg/redirect.svg"
                 alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- company not found -->
  <ng-container *ngIf="!isCompanyAvailable && !isLoaded">
    <div class="custom-card mb-0 custom-height">
      <div class="profile-card">
        <div class="profile-top"
             *ngIf="linkedInScrappedData.tabUrl.includes('linkedin.com') &&
                (linkedInScrappedData.company.companyName ||
                linkedInScrappedData.contact.experience[0].companyName)"
        >
          <div class="profile-logo">
            <img [src]="linkedInScrappedData.company.companyLogo ?
                   linkedInScrappedData.company.companyLogo :
                   linkedInScrappedData.contact.experience[0].companyLogo ?
                   linkedInScrappedData.contact.experience[0].companyLogo :
                    'assets/images/png/company-placeholder.png'"
                 alt=""
            >
          </div>
          <div class="profile-content">
            <div class="profile-info">
              <h6 #CMP
                  class="overflow-text"
                  [tooltip]="CMP.offsetWidth < CMP.scrollWidth ?
                       linkedInScrappedData.company.companyName ||
                       linkedInScrappedData.contact.experience[0].companyName :''"
                  placement="bottom"
              >
                {{
                  linkedInScrappedData.company.companyName ||
                  linkedInScrappedData.contact.experience[0].companyName
                }}
              </h6>
              <div class="action no-cursor">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt=""
                  >
                </div>
                <div class="add-to-list">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/addList.svg"
                       alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cmp-profile-bottom">
          <div class="vts-border-theme not-found text-center">
            <img class="vts-svg-primary"
                 src="assets/images/svg/not-found.svg"
                 alt=""
            >
            <p>{{ ls.data.company.noCompanyDataFoundYetWe }}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- skeleton -->
  <ng-container *ngIf="isCompanyAvailable && isLoaded">
    <div class="skeleton">
      <div class="custom-skeleton">
        <div class="p-d-flex p-mb-3">
          <div class="circle p-mr-2 skeleton-effect"
               size="4rem"
          ></div>
          <div class="stripes">
            <div class="p-mb-2 skeleton-effect"
                 *ngFor="let _ of [1,2]"
            ></div>
          </div>
        </div>
        <div class="stripes fullStripes">
          <div class="p-mb-2 skeleton-effect"
               *ngFor="let _ of [1,2,3,4,5,6,7]"
          ></div>
        </div>
      </div>
      <div class="custom-skeleton">
        <div class="stripes fullStripes">
          <div class="p-mb-2 skeleton-effect"
               *ngFor="let _ of [1,2,3,4,5,6,7]"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
