import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class KeycloakService {

  _keycloak: Keycloak | undefined;
  authenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  get keycloak() {
    if (!this._keycloak) {
      this._keycloak = new Keycloak({
        url: environment.KEYCLOAK_URL,
        realm: environment.KEYCLOAK_REALM,
        clientId: environment.KEYCLOAK_CLIENT_ID,
      });
    }

    return this._keycloak;
  }

  async init() {
    const isChromeTab: boolean = !!(chrome && chrome.runtime && chrome.runtime.id);
    if (isChromeTab) {
      return;
    }

    const authenticated: boolean = await this.keycloak.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
      silentCheckSsoRedirectUri: `${window.location.origin}/assets/silent-check-sso.html`,
      responseMode: 'fragment',
      pkceMethod: 'S256',
      redirectUri: `${window.location.origin}/assets/success-auth.html`,
    });

    this.authenticatedSubject.next(authenticated);
    return authenticated;
  }

  reinit() {
    this._keycloak = undefined;
    return this.init();
  }

  login() {
    return this.keycloak.login();
  }

  logout() {
    return this.keycloak.logout();
  }

  async refreshToken() {
    return this.keycloak.updateToken();
  }

}
