import { Component, Input } from '@angular/core';
import { GlobalHelper } from '../../global.helper';
import { LanguageService } from '../../dynamic/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent {

  @Input('isLinkedInUrl') isLinkedInUrl: boolean = false;
  @Input('isLinkedCmpUrl') isLinkedCmpUrl: boolean = false;

  tabs: string[] = ['contact', 'company', 'employee', 'search', 'activity'];

  constructor(
    public ls: LanguageService,
    public globalHelper: GlobalHelper) {
  }

}
