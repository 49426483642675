import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../../dynamic/language.service';
import { COPY, GlobalHelper, goToExternalLink } from '../../global.helper';
import { CommunicationService } from '../../services/communication.service';
import { emptyLinkedInScrappedData, ILinkedInScrappedData } from '../../global.types';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  linkedInScrappedData: ILinkedInScrappedData = COPY(emptyLinkedInScrappedData);
  private subscription: Subscription = new Subscription();

  constructor(
    public ls: LanguageService,
    public globalHelper: GlobalHelper,
    public communicationService: CommunicationService,
  ) {
    this.globalHelper.getSubscriptionInfo();
  }

  ngOnInit() {
    this.subscription.add(
      this.communicationService.linkedInScrappedData$.subscribe((data: ILinkedInScrappedData) => {
        this.linkedInScrappedData = data;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  returnBack() {
    this.globalHelper.toggleFill(this.globalHelper.previousTab, false);
  }

  minimizeExt(action: string) {
    this.globalHelper.pluginSettings.minimize = action;
    this.communicationService.postMessageToParent(this.globalHelper.pluginSettings);
  }

  protected readonly goToExternalLink = goToExternalLink;
  protected readonly environment = environment;
}
