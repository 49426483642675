import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { LanguageService } from './dynamic/language.service';
import { GlobalHelper, goToExternalLink } from './global.helper';
import { CommunicationService } from './services/communication.service';

declare const chrome: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})

export class AppComponent implements OnInit {

  locationUrl: string = '';
  isChromeTab: boolean = false;
  isLinkedInUrl: boolean = false;
  isLinkedCmpUrl: boolean = false;

  readonly environment: any = environment;
  protected readonly goToExternalLink = goToExternalLink;

  constructor(
    public ls: LanguageService,
    public authService: AuthService,
    public globalHelper: GlobalHelper,
    public communicationService: CommunicationService,
  ) {
    this.checkIfChromeTab();
  }

  ngOnInit() {
    if (!this.isChromeTab) {
      this.authService.handleAuthenticationStatus();
      this.setNavigation()
        .then();
    }
  }

  checkIfChromeTab() {
    this.isChromeTab = !!(chrome && chrome.runtime && chrome.runtime.id);
  }

  promiscuousMode() {
    if (this.communicationService.linkedInScrappedData.urlType === 'other') {
      return;
    }
    this.authService.promiscuousMode(this.communicationService.linkedInScrappedData)
      .then();
  }

  async setNavigation() {
    const linkedInUrl: any = await this.globalHelper.getActiveTabLinkedinUrl(this.communicationService.linkedInScrappedData.tabUrl);
    this.locationUrl = linkedInUrl.locationUrl;
    this.isLinkedInUrl = linkedInUrl.isLinkedInUrl;
    this.isLinkedCmpUrl = this.communicationService.linkedInScrappedData.tabUrl.includes('https://www.linkedin.com/company/');
    this.globalHelper.activeTab = this.isLinkedInUrl ? (this.isLinkedCmpUrl ? 'company' : 'contact') : 'company';
    this.promiscuousMode();
  }

}
