import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  ICompanyLogo,
  ICompanyLogosReq,
  ICompanyProfile,
  IContactLogo,
  IContactLogoReq,
  IContactProfile,
  ILinkedInPayload,
} from './common.service.types';
import { IDashboardData } from '../components/employee/employee.types';
import { IFeedbackPayload, IGetFeedbackResponse } from '../global.types';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  constructor(private http: HttpClient) {
  }

  getContactProfile(request: ILinkedInPayload) {
    return lastValueFrom(this.http.post<IContactProfile>(`${environment.API_URL}search/contacts/linkedin`, request));
  }

  getCompanyProfile(request: ILinkedInPayload) {
    return lastValueFrom(this.http.post<ICompanyProfile>(`${environment.API_URL}search/contacts/linkedin`, request));
  }

  getList(body: any) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}search/myList/allList`, body));
  }

  getContactListSave(contactId: string) {
    return lastValueFrom(this.http.get<any>(`${environment.API_URL}search/contacts/getContactListSave/${contactId}`));
  }

  getCompanyListSave(companyId: string) {
    return lastValueFrom(this.http.get<any>(`${environment.API_URL}search/companies/getCompanyListSave/${companyId}`));
  }

  getDefaultCntList() {
    return lastValueFrom(this.http.get<any>(`${environment.API_URL}search/contacts/contactList/mylist/`));
  }

  getDefaultCmpList() {
    return lastValueFrom(this.http.get<any>(`${environment.API_URL}search/companies/companyList/mylist/`));
  }

  getChExtCredits() {
    return lastValueFrom(this.http.get<any>(`${environment.API_URL}search/contacts/active-subscription`));
  }

  getContactList(body: any) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}search/companies/similar-cmp-employee`, body));
  }

  chromeExtRecentSearch(body: any, limit: number = 0, offset: number = 6) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}search/contacts/chromeExtRecentSearch/${limit}/${offset}`, body));
  }

  contactView(contactId: string) {
    return lastValueFrom(this.http.get<any>(`${environment.API_URL}search/contacts/view/${contactId}/true`));
  }

  saveCntList(body: any) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}search/contacts/saveContactList`, body));
  }

  saveCmpList(body: any) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}search/companies/saveCompanyList`, body));
  }

  getDepartmentSeniority() {
    return lastValueFrom(this.http.get<IDashboardData>(`${environment.API_URL}search/stats/dashboard/detailed-count`));
  }

  createList(body: any, typeList: string, type: string, listName: any) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}search/${typeList}/${type}List/save-create-list/${listName}`, body));
  }

  getSetting() {
    return lastValueFrom(this.http.get<any>(`${environment.API_URL}account/user/chromeExtSetting`));
  }

  saveSetting(body: any) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}account/user/saveChromeExtSetting`, body));
  }

  getCompanyLogos(request: ICompanyLogosReq) {
    return lastValueFrom(this.http.post<ICompanyLogo[]>(`${environment.API_URL}account/public/company-logo`, request));
  }

  getContactLogos(request: IContactLogoReq) {
    return lastValueFrom(this.http.post<IContactLogo[]>(`${environment.API_URL}account/public/contact-logo`, request));
  }

  getProfileDetails() {
    return lastValueFrom(this.http.get<any>(`${environment.API_URL}account/profile/profileDetails`));
  }

  getSubscriptionInfo() {
    return lastValueFrom(this.http.get<any>(`${environment.API_URL}account/user/subscription/active-subs-addons`));
  }

  saveUserFeedback(request: IFeedbackPayload) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}search/userFeedback/save`, request));
  }

  getUserFeedback(contactId: string[]) {
    return lastValueFrom(this.http.post<IGetFeedbackResponse[]>(`${environment.API_URL}search/userFeedback/get`, { contactId }));
  }

}
