export interface IDynamicFields<T> {
  [key: string]: T;
}

export interface IAvatarStyle {
  backgroundColor: string;
  color: string;
}

export const defaultAvtarStyle: IAvatarStyle = {
  backgroundColor: '#E8E8E8',
  color: '#8C8A94',
};
