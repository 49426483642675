import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { LanguageService } from './dynamic/language.service';
import { GlobalHelper, goToExternalLink } from './global.helper';
import { CommunicationService } from './services/communication.service';

declare const chrome: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})

export class AppComponent implements OnInit {

  previousTab: string = '';
  activeTab: string = 'contact';
  noProtocolLinkedInUrl: string = '';
  isChromeTab: boolean;
  showBack: boolean = false;
  isLinkedInUrl: boolean = false;
  isLinkedCmpUrl: boolean = false;

  readonly environment: any = environment;
  protected readonly goToExternalLink = goToExternalLink;

  constructor(
    public ls: LanguageService,
    public authService: AuthService,
    public globalHelper: GlobalHelper,
    public communicationService: CommunicationService,
  ) {
    this.checkIfChromeTab();
  }

  ngOnInit() {
    if (!this.isChromeTab) {
      this.authService.handleAuthenticationStatus();
      this.communicationService.postMessageToParent(this.globalHelper.pluginSettings);
      this.setNavigation()
        .then();
      if (this.authService.loginUserDetails) {
        this.globalHelper.getSettings('onload');
      }
    }
  }

  checkIfChromeTab() {
    this.isChromeTab = !!(chrome && chrome.runtime && chrome.runtime.id);
  }

  promiscuousMode() {
    if (this.communicationService.linkedInScrappedData.urlType === 'other') {
      return;
    }
    this.authService.promiscuousMode(this.communicationService.linkedInScrappedData)
      .then();
  }

  async setNavigation() {
    const linkedInUrl: any = await this.globalHelper.getActiveTabLinkedinUrl(this.communicationService.linkedInScrappedData.tabUrl);
    this.noProtocolLinkedInUrl = linkedInUrl.finalLinkedInUrl;
    this.isLinkedInUrl = linkedInUrl.isLinkedInUrl || !linkedInUrl.finalLinkedInUrl;
    this.isLinkedCmpUrl = this.communicationService.linkedInScrappedData.tabUrl.includes('https://www.linkedin.com/company/');
    this.activeTab = this.isLinkedInUrl ? (this.isLinkedCmpUrl ? 'company' : 'contact') : 'company';
    this.promiscuousMode();
  }

  searchProspects(clicked: string) {
    this.globalHelper.pluginSettings.clicked = clicked;
    this.communicationService.postMessageToParent(this.globalHelper.pluginSettings);
  }

  triggerParentBodyClick() {
    window.close();
  }

  toggleFill(item: string, isInternalLink: boolean) {
    this.showBack = false;
    this.globalHelper.isExpanded.open = false;
    this.previousTab = this.activeTab;
    this.activeTab = this.activeTab !== item ? item : this.activeTab;
    if (item === 'search') {
      goToExternalLink(`${environment.PRE_URL}/search`, true);
      setTimeout(() => {
        this.toggleFill(this.isLinkedCmpUrl ? 'company' : 'contact', false);
      }, 500);
    }
    if (item && isInternalLink) {
      this.showBack = true;
    }
  }

  returnBack() {
    this.toggleFill(this.previousTab, false);
  }

  minimizeExt(action: string) {
    this.globalHelper.pluginSettings.minimize = action;
    this.communicationService.postMessageToParent(this.globalHelper.pluginSettings);
  }

}
