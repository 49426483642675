<div class="extension">
  <div class="extension-header">
    <img class="logo"
         src="assets/images/png/predictiv-logo.png"
         alt=""
         (click)="goToExternalLink(environment.PRE_URL,true)"
    >
    <i class="fa fa-window-minimize minimize"
       aria-hidden="true"
       placement="bottom"
       [tooltip]="ls.data.global.buttons.close"
       (click)="triggerParentBodyClick()"
    ></i>
  </div>
  <div class="extension-body">
    <div class="dash-wrapper">
      <p>{{ ls.data.settings.platform }}</p>
      <div class="desc">
        <div class="desc-left">
          <img class="vts-svg-primary"
               src="assets/images/svg/dashboard.svg"
               alt=""
          >
          <a (click)="goToExternalLink(environment.PRE_URL+'/dashboard',true)">
            {{ ls.data.dashboard.goToPredictiv }}
          </a>
        </div>
        <div class="desc-right">
          <img class="vts-svg-primary"
               src="assets/images/svg/xd-redirect.svg"
               alt=""
               (click)="goToExternalLink(environment.PRE_URL+'/dashboard',true)"
          >
        </div>
      </div>
    </div>
    <div class="dash-wrapper">
      <p>{{ ls.data.dashboard.extension }}</p>
      <div class="desc">
        <div class="desc-left">
          <img src="assets/images/svg/linkedin.svg" alt="">
          <a (click)="goToExternalLink('https://www.linkedin.com/feed/',true)">
            {{ ls.data.dashboard.linkedIn }}
          </a>
        </div>
        <div class="desc-right">
          <img class="vts-svg-primary"
               src="assets/images/svg/xd-redirect.svg"
               alt=""
               (click)="goToExternalLink('https://www.linkedin.com/feed/',true)"
          >
        </div>
      </div>
    </div>
  </div>
</div>
