import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { COPY, GlobalHelper } from '../../global.helper';
import { LanguageService } from '../../dynamic/language.service';
import { CommunicationService } from '../../services/communication.service';
import { emptyLinkedInScrappedData, ILinkedInScrappedData } from '../../global.types';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit, OnDestroy {

  linkedInScrappedData: ILinkedInScrappedData = COPY(emptyLinkedInScrappedData);
  private subscription: Subscription = new Subscription();

  tabs: string[] = ['contact', 'company', 'employee', 'search', 'activity'];

  constructor(
    public ls: LanguageService,
    public globalHelper: GlobalHelper,
    public communicationService: CommunicationService) {
  }

  ngOnInit() {
    this.subscription.add(
      this.communicationService.linkedInScrappedData$.subscribe((data: ILinkedInScrappedData) => {
        this.linkedInScrappedData = data;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
