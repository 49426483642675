import { IStyles } from '../themes.types';

export const ThemeBlue: IStyles = {
  red: '#ff0000',
  text: '#070808',
  green: '#37a65e',
  hover: '#292A71',
  primary: '#292A71',
  shadow: '#00000029',
  tagColor: '#aeb4b7',
  secondary: '#292A71',
  background: '#F8F8FF',
  primarySvg: 'initial',
  secondarySvg: 'initial',
  borderColor: '#70707017',
  whiteBackground: '#FFFFFF',
  gradientBackground: 'linear-gradient(to top, #a3a5e2, #f2f3fe)',
};
