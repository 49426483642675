<div class="main-body">
  <!-- employees top filter -->
  <div class="custom-card">
    <div class="profile-card">
      <div class="vts-tag-color panel-title">
        {{ ls.data.dashboard.employees }}
      </div>
      <div class="emp-bottom">
        <div class="auto-complete">
          <div class="search-ico">
            <img class="vts-svg-primary"
                 src="assets/images/svg/search-icon.svg"
                 alt=""
            >
          </div>
          <div class="vts-border-theme input-text">
            <input class="vts-inputText form-control"
                   type="text"
                   id="search"
                   name="search"
                   [(ngModel)]="searchText"
                   (input)="triggerSearch()"
                   [placeholder]="ls.data.employee.startTypingToSearch"
            >
          </div>
          <div class="search-btn">
            <button type="submit"
                    class="vts-theme-button btn btn-primary btn-sm"
                    (click)="searchEmployee()"
                    [disabled]="!searchText"
            >
              {{ ls.data.dashboard.search }}
            </button>
          </div>
        </div>
        <div class="filter-box">
          <div class="vts-border-theme filter"
               [ngClass]="{'vts-theme-button filtered': isFilterApplied}"
               (click)="openFilters()"
          >
            <img class="vts-svg-primary"
                 [ngClass]="{'vts-svg-primary': !isFilterApplied}"
                 [src]="!isFilterApplied ? 'assets/images/svg/filter-icon.svg': 'assets/images/svg/white-filter-icon.svg'"
                 alt=""
            >
            <span>{{ selectedFilterOption ? selectedFilterOption : 'Filter' }}</span>
          </div>
          <div class="filter-options"
               *ngIf="openFilter"
          >
            <ul class="vts-border-theme">
              <li (click)="selectedFilters('Seniority')">
                {{ ls.data.employee.seniority }}
              </li>
              <li (click)="selectedFilters('Department')">
                {{ ls.data.employee.department }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="applied-filter"
           *ngIf="isFilterApplied"
      >
        <div class="filter-result-top">
          <div class="filter-result">
            {{ ls.data.employee.appliedFilter }}
            <span class="vts-theme-bg counter">
              {{ selectedFiltersSeniority.length + selectedFiltersDept.length }}
            </span>
          </div>
          <div class="filter-result clear-all"
               *ngIf="(selectedFiltersSeniority.length + selectedFiltersDept.length) !== 0"
               (click)="clearAll()"
          >
            {{ ls.data.employee.clearAll }}
          </div>
        </div>
        <div class="filter-result-bottom">
          <ul *ngIf="filtersSeniority.length && selectedFilterOption === 'Seniority'">
            <li #FEL
                *ngFor="let filter of filtersSeniority"
                [tooltip]="FEL.offsetWidth < FEL.scrollWidth ? filter :''"
                placement="bottom"
                (click)="addFilters(filter)"
                [ngClass]="{'vts-text-box selected': selectedFiltersSeniority.includes(filter)}"
            >
              {{ filter }}
            </li>
          </ul>
          <ul *ngIf="filtersSeniority.length && selectedFilterOption === 'Department'">
            <li #FEL
                *ngFor="let filter of filtersDept"
                [tooltip]="FEL.offsetWidth < FEL.scrollWidth ? filter :''"
                placement="bottom"
                (click)="addFilters(filter)"
                [ngClass]="{'vts-text-box selected': selectedFiltersDept.includes(filter)}"
            >
              {{ filter }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!--  employees bottom -->
  <ng-container *ngIf="!globalHelper.employeeData.cndDataLoader && globalHelper.employeeData.cntData &&
                   globalHelper.employeeData.cntData.length"
  >
    <div class="custom-card mb-0">
      <div class="profile-card">
        <div class="cmp-profile-bottom">
          <div class="card-title">
            <div class="vts-tag-color panel-title">
              {{ globalHelper.employeeData.cntData.length }}
              {{ globalHelper.employeeData.cntData.length > 1 ? 'Employees' : 'Employee' }}
            </div>
            <div class="vts-icon-color link"
                 (click)="goToProspect()"
            >
              {{ ls.data.employee.viewMore }}
              <img class="vts-svg-primary"
                   src="assets/images/svg/redirect.svg"
                   alt=""
              >
            </div>
          </div>
          <div class="emp-details-list">
            <div class="emp-list-item"
                 *ngFor="let employee of globalHelper.employeeData.cntData;let index = index"
            >
              <div class="emp-list-top">
                <div class="emp-list-top-left">
                  <div class="employee-logo">
                    <ng-container *ngIf="employee.imageUrl; else initials">
                      <img [src]="employee.imageUrl" alt="">
                    </ng-container>
                    <ng-template #initials>
                      <div class="initials"
                           [ngStyle]="employee.avatarStyle"
                      >
                        {{ globalHelper.getInitials(employee.fullName) }}
                      </div>
                    </ng-template>
                  </div>
                  <div class="employee-info">
                    <div class="employee-title">
                      <h4 #FNM
                          class="vts-icon-color overflow-text"
                          [tooltip]="FNM.offsetWidth < FNM.scrollWidth ? employee.fullName :''"
                          placement="bottom"
                          (click)="globalHelper.goToDetails(employee.contactId,'contact')"
                      >
                        {{ employee.fullName }}
                      </h4>
                      <ng-container *ngIf="employee?.contact_li_url">
                        <img class="social-icon"
                             src="assets/images/svg/linkedin.svg"
                             alt=""
                             (click)="goToExternalLink(employee.contact_li_url,false)"
                        >
                      </ng-container>
                      <ng-container *ngIf="employee?.facebook_url">
                        <img class="social-icon"
                             src="assets/images/svg/facebook.svg"
                             alt=""
                             (click)="goToExternalLink(employee.facebook_url,false)"
                        >
                      </ng-container>
                    </div>
                    <p #JTE
                       class="overflow-text"
                       [tooltip]="JTE.offsetWidth < JTE.scrollWidth ? employee.jobTitle :''"
                       placement="bottom"
                    >
                      {{ employee.jobTitle ? employee.jobTitle : "" }}
                    </p>
                  </div>
                </div>
                <div class="emp-list-top-right">
                  <div class="action">
                    <div class="add-to-list">
                      <img class="vts-svg-primary cursor"
                           [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                           alt=""
                           [tooltip]="ls.data.company.toolTip.addToList"
                           placement="top"
                           (click)="addToCntList(employee.contactId, index, employee.companyId)"
                      >
                      <div class="vts-border-theme save-contact"
                           *ngIf="isCntListAdded[index]"
                      >
                        <div class="form-group">
                          <ng-select #cntListSelect
                                     bindLabel="value"
                                     bindValue="value"
                                     [multiple]="true"
                                     [addTag]="globalHelper.addList"
                                     [addTagText]="ls.data.company.createNewList"
                                     [items]="globalHelper.cntListData.cntListDataAvl"
                                     (change)="globalHelper.onListChange('contact')"
                                     [(ngModel)]="globalHelper.selectedListType.selectedCntList"
                                     [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                          ></ng-select>
                        </div>
                        <div class="action-buttons">
                            <span class="vts-theme-bg vts-border-theme fill-btn"
                                  (click)="saveContactList(globalHelper.selectedListType.selectedCntList, index, employee.contactId, employee.companyId)"
                            >
                              {{ ls.data.global.buttons.save }}
                            </span>
                          <span class="vts-border-theme vts-icon-color"
                                (click)="onListCancel(index)"
                          >
                              {{ ls.data.global.buttons.cancel }}
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="emp-list-bottom">
                <div class="emp-list-bottom-left">
                  <div class="vts-green-color-border emp-details">
                    <div #item
                         class="contact-details"
                         [ngClass]="{
                            'green-contact': employee.alreadyView && (employee.emailAddress || employee.phoneOffice || employee.phoneDirect),
                            'not-available': !employee.emailAddress && !employee.phoneOffice && !employee.phoneDirect,
                            'disabled': employee.isContactCollapse
                         }"
                         (click)="viewContact(employee.contactId,employee.isContactCollapse)"
                         (mouseenter)="globalHelper.showHover(item)"
                         (mouseleave)="globalHelper.hideHover(item)"
                    >
                      <div class="details-wrapper">
                        <img class="vts-svg-primary"
                             src="assets/images/svg/xd-email.svg"
                             alt=""
                             [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.workEmail : ''"
                             placement="top"
                             [ngClass]="{
                                 'item-not-available': !employee.emailAddress,
                                 'item-green': employee.alreadyView && employee.emailAddress
                             }"
                        >
                        <span class="divider">|</span>
                        <img class="vts-svg-primary"
                             src="assets/images/svg/xd-office-phone.svg"
                             alt=""
                             [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.phoneOffice : ''"
                             placement="top"
                             [ngClass]="{
                                 'item-not-available': !employee.phoneOffice,
                                 'item-green': employee.alreadyView && employee.phoneOffice
                             }"
                        >
                        <span class="divider">|</span>
                        <img class="vts-svg-primary"
                             src="assets/images/svg/xd-cell-phone.svg"
                             alt=""
                             [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.directPhone : ''"
                             placement="top"
                             [ngClass]="{
                               'item-not-available': !employee.phoneDirect,
                               'item-green': employee.alreadyView && employee.phoneDirect
                             }"
                        >
                      </div>
                      <div class="show-details"
                           *ngIf="globalHelper.hoveredItem === item &&
                              (employee.emailAddress || employee.phoneOffice || employee.phoneDirect) &&
                              !employee.alreadyView"
                           [tooltip]="!employee.alreadyView ? ls.data.global.toolTip.thisCostOneCredit : ''"
                           placement="right"
                      >
                        <p>{{ ls.data.actions.showDetails }}</p>
                      </div>
                    </div>
                    <div class="hide-link"
                         *ngIf="employee.isContactCollapse"
                         (click)="viewContact(employee.contactId, employee.isContactCollapse)"
                    >
                      {{ ls.data.actions.hide }}
                    </div>
                  </div>
                  <div class="emp-details-view"
                       *ngIf="employee.isContactCollapse && contactProfile"
                  >
                    <ul>
                      <li [ngClass]="{'na': !contactProfile.emailAddress}">
                        <p class="label">{{ ls.data.company.workEmail }}</p>
                        :
                        <app-user-feedback [attributeValue]="contactProfile.emailAddress"
                                           [contactId]="contactProfile.contactId"
                                           [attributeName]="'email'"
                                           [isContactView]="true"
                                           [isListView]="true"
                        ></app-user-feedback>
                      </li>
                      <li [ngClass]="{'na': !contactProfile.phoneOffice}">
                        <p class="label">{{ ls.data.company.phoneOffice }}</p>
                        :
                        <app-user-feedback [attributeValue]="contactProfile.phoneOffice"
                                           [contactId]="contactProfile.contactId"
                                           [attributeName]="'phoneOffice'"
                                           [isContactView]="true"
                                           [isListView]="true"
                        ></app-user-feedback>
                      </li>
                      <li [ngClass]="{'na':!contactProfile.phoneDirect}">
                        <p class="label">{{ ls.data.company.directPhone }}</p>
                        :
                        <app-user-feedback [attributeValue]="contactProfile.phoneDirect"
                                           [contactId]="contactProfile.contactId"
                                           [attributeName]="'phoneDirect'"
                                           [isContactView]="true"
                                           [isListView]="true"
                        ></app-user-feedback>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- no data found -->
  <ng-container *ngIf="!globalHelper.employeeData.cndDataLoader && globalHelper.employeeData.cntData &&
                  !globalHelper.employeeData.cntData.length"
  >
    <div class="custom-card">
      <h1 class="coming-soon">{{ ls.data.employee.noDataFound }}</h1>
    </div>
  </ng-container>

  <!-- skeleton -->
  <ng-container *ngIf="globalHelper.employeeData.cndDataLoader">
    <div class="skeleton">
      <div class="custom-skeleton">
        <div class="stripes fullEmpStripes">
          <div class="p-mb-2 mb-3 skeleton-effect"
               *ngFor="let _ of [1,2,3,4,5,6,7,8,9,10,11,12,13,14]"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
