import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GlobalHelper } from '../global.helper';
import { HttpClient } from '@angular/common/http';
import { KeycloakService } from './keycloak.service';
import { TEntityType, TUrlType } from '../global.types';
import { ThemesService } from '../themes/themes.service';
import { ESupportedThemes } from '../themes/themes.types';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../dynamic/language.service';
import { CommunicationService } from './communication.service';
import { ILoginUser, IPlanUserExpiration } from './auth.service.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  sessionCheckInterval: number = 30 * 1000;
  sessionIntervalId: number;
  planIntervalId: number;
  planExpired: boolean = false;

  constructor(
    private http: HttpClient,
    public ls: LanguageService,
    private kcService: KeycloakService,
    private communicationService: CommunicationService,
    private themesService: ThemesService,
    private globalHelper: GlobalHelper,
  ) {
  }

  get isLoggedIn() {
    return this.kcService.keycloak.authenticated || false;
  }

  public get loginUserDetails(): ILoginUser | null {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      return JSON.parse(userInfo);
    } else {
      return null;
    }
  }

  async me() {
    if (this.loginUserDetails?.subTier) {
      return;
    }

    const userPromise = lastValueFrom(this.http.get<any>(`${environment.API_URL}account/profile/profileDetails`));
    const subsPromise = lastValueFrom(this.http.get<any>(`${environment.API_URL}search/contacts/active-subscription`));
    const [userDataRes, subsDataRes] = await Promise.all([userPromise, subsPromise]);

    const loginUser: ILoginUser = {
      userData: {
        firstName: userDataRes.firstName,
        lastName: userDataRes.lastName,
      },
      subTier: subsDataRes.tier,
    };

    this.setUserData(loginUser);
  }

  setUserData(loginUser: ILoginUser) {
    localStorage.setItem('userInfo', JSON.stringify(loginUser));
  }

  removeUserData() {
    localStorage.removeItem('userInfo');
  }

  promiscuousMode(urlType: TUrlType, entityType: TEntityType, request: any) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}promiscuous-mode/${urlType}/${entityType}`, request));
  }

  sessionChecker() {
    this.sessionIntervalId = Number(setInterval(() => {
      this.kcService.keycloak.updateToken(270)
        .catch(() => this.logout());
    }, this.sessionCheckInterval));
  }

  logout() {
    this.stopTimers();
    this.themesService.defineThemes('blue-theme' as ESupportedThemes);
    this.kcService.keycloak?.clearToken();
    this.removeUserData();
    this.communicationService.postLogoutMsg();
  }

  get token() {
    return this.kcService.keycloak?.token;
  }

  get isTokenExpired() {
    return this.kcService.keycloak?.isTokenExpired();
  }

  refreshToken() {
    return this.kcService.refreshToken();
  }

  handleAuthenticationStatus() {
    this.kcService.authenticatedSubject.subscribe(authenticated => {
      if (authenticated) {
        this.oneTimeCheckExpiration()
          .then();

        if (!this.planExpired) {
          this.me()
            .then();
          this.sessionChecker();
          this.checkExpirationTimer();
          this.globalHelper.getSettings();
        }
      } else {
        this.stopTimers();
        this.removeUserData();
      }
    });
  }

  async fetchPlanExpiration() {
    try {
      const res: IPlanUserExpiration =
        await lastValueFrom(this.http
          .get<IPlanUserExpiration>(`${environment.API_URL}account/accounts/plan-expiration`));
      return res.planExpired;
    } catch (error: unknown) {
      console.error(error);
      return true;
    }
  }

  async oneTimeCheckExpiration() {
    this.planExpired = await this.fetchPlanExpiration();
    if (this.planExpired) {
      this.communicationService.postPlanExpiredMsg();
    }
  }

  checkExpirationTimer() {
    this.planIntervalId = Number(setInterval(async() => {
      this.planExpired = await this.fetchPlanExpiration();
      if (this.planExpired) {
        this.communicationService.postPlanExpiredMsg();
      }
    }, this.sessionCheckInterval));
  }

  stopTimers() {
    clearInterval(this.sessionIntervalId);
    clearInterval(this.planIntervalId);
  }
}
