<div class="main-body">
  <ng-container *ngIf="isContactAvailable && isLoaded">
    <!-- contact card -->
    <div class="custom-card">
      <div class="profile-card">
        <div class="saved-tag"
             *ngIf="globalHelper.isSaved.isCntSaved"
        >
          <img src="assets/images/svg/saved-tag.svg" alt="">
        </div>
        <div class="profile-top">
          <div class="profile-logo">
            <ng-container *ngIf="globalHelper.pDetails.imageUrl &&
                             globalHelper.pDetails.imageUrl !== linkedinContactPlaceholder; else initials"
            >
              <img [src]="globalHelper.pDetails.imageUrl" alt="">
            </ng-container>
            <ng-template #initials>
              <div class="initials"
                   [ngStyle]="globalHelper.pDetails.avatarStyle"
              >
                {{ globalHelper.getInitials(globalHelper.pDetails.fullName) }}
              </div>
            </ng-template>
          </div>
          <div class="profile-content">
            <div class="profile-info">
              <h6 #FNE
                  class="overflow-text"
                  placement="top"
                  [tooltip]="FNE.offsetWidth < FNE.scrollWidth ? globalHelper.pDetails.fullName : ''"
              >
                {{ globalHelper.pDetails.fullName ? globalHelper.pDetails.fullName : ls.data.global.misc.notAvailable }}
              </h6>
              <div class="action">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src=" assets/images/svg/redirect.svg"
                       alt=""
                       (click)="globalHelper.goToDetails(globalHelper.pDetails.contactId,'contact')"
                  >
                </div>
                <div class="add-to-list">
                  <img class="vts-svg-primary cursor"
                       [src]="!globalHelper.isSaved.isCntSaved ? 'assets/images/svg/addList.svg' :
                          'assets/images/svg/addList-fill.svg'"
                       alt=""
                       [tooltip]="ls.data.company.toolTip.addToList"
                       placement="top"
                       (click)="addToCntList()"
                  >
                  <div class="vts-border-theme save-contact"
                       *ngIf="isCntListAdded"
                  >
                    <div class="form-group">
                      <ng-select #cntListSelect
                                 bindLabel="value"
                                 bindValue="value"
                                 [addTag]="globalHelper.addList"
                                 [addTagText]="ls.data.company.createNewList"
                                 [items]="globalHelper.cntListData.cntListDataAvl"
                                 (change)="globalHelper.onListChange('contact')"
                                 [(ngModel)]="globalHelper.selectedListType.selectedCntList"
                                 [multiple]="true" [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                      ></ng-select>
                    </div>
                    <div class="action-buttons">
                      <span class="vts-theme-bg vts-border-theme fill-btn"
                            (click)="saveContactList(globalHelper.selectedListType.selectedCntList)"
                      >
                        {{ ls.data.global.buttons.save }}
                      </span>
                      <span class="vts-border-theme vts-icon-color"
                            (click)="onListCancel('contact')"
                      >
                        {{ ls.data.global.buttons.cancel }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span #JTE
                  class="job-title overflow-text"
                  placement="bottom"
                  [tooltip]="JTE.offsetWidth < JTE.scrollWidth ? globalHelper.pDetails.job_title : ''"
            >
              {{ globalHelper.pDetails.job_title ? globalHelper.pDetails.job_title : ls.data.global.misc.notAvailable }}
            </span>
          </div>
        </div>
        <div class="profile-bottom">
          <div class="handle-contact-view">
            <div class="vts-theme-button custom-btn disabled"
                 [ngClass]="{'disabled' : isContactView ||
                    (globalHelper.pDetails.work_email == 'Not Available' &&
                    globalHelper.pDetails.phone_office == 'Not Available' &&
                    globalHelper.pDetails.phone_direct == 'Not Available')
                 }"
                 (click)="showDetails()"
            >
              {{ ls.data.contact.showContact }}
            </div>
          </div>
          <div class="cnt-details-list">
            <div class="cnt-details">
              <div class="vts-background-color cnt-title">
                {{ ls.data.contact.officeEmail }}
              </div>
              <div class="cnt-data">
                <div class="check-mark"
                     *ngIf="isFaCheckFaCopyShowEM"
                >
                  <img class="vts-svg-green"
                       src=" assets/images/svg/checked.svg"
                       alt=""
                  >
                </div>
                <app-user-feedback [attributeValue]="globalHelper.pDetails.work_email"
                                   [contactId]="globalHelper.pDetails.contactId"
                                   [isContactView]="isContactView"
                                   [attributeName]="'email'"
                ></app-user-feedback>
              </div>
            </div>
            <div class="cnt-details">
              <div class="vts-background-color cnt-title">
                {{ ls.data.contact.officePhone }}
              </div>
              <div class="cnt-data">
                <div class="check-mark"
                     *ngIf="isFaCheckFaCopyShowPO"
                >
                  <img class="vts-svg-green"
                       src=" assets/images/svg/checked.svg"
                       alt=""
                  >
                </div>
                <app-user-feedback [attributeValue]="globalHelper.pDetails.phone_office"
                                   [contactId]="globalHelper.pDetails.contactId"
                                   [isContactView]="isContactView"
                                   [attributeName]="'phoneOffice'"
                ></app-user-feedback>
              </div>
            </div>
            <div class="cnt-details">
              <div class="vts-background-color cnt-title">
                {{ ls.data.company.directPhone }}
              </div>
              <div class="cnt-data">
                <div class="check-mark"
                     *ngIf="isFaCheckFaCopyShowPD"
                >
                  <img class="vts-svg-green"
                       src=" assets/images/svg/checked.svg"
                       alt=""
                  >
                </div>
                <app-user-feedback [attributeValue]="globalHelper.pDetails.phone_direct"
                                   [contactId]="globalHelper.pDetails.contactId"
                                   [isContactView]="isContactView"
                                   [attributeName]="'phoneDirect'"
                ></app-user-feedback>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- company card -->
    <div class="custom-card mb-0">
      <div class="profile-card">
        <div class="profile-top">
          <div class="profile-logo">
            <img [src]="globalHelper.pDetails.cmpLogo && globalHelper.pDetails.cmpLogo !== '' ?
                    globalHelper.pDetails.cmpLogo :
                    linkedInScrappedData.contact.experience[0].companyLogo !== '' ?
                    linkedInScrappedData.contact.experience[0].companyLogo :
                    'assets/images/png/company-placeholder.png'"
                 alt=""
            >
          </div>
          <div class="profile-content">
            <div class="profile-info">
              <h6 #CMP
                  class="overflow-text"
                  placement="bottom"
                  [tooltip]="CMP.offsetWidth < CMP.scrollWidth ? globalHelper.pDetails.company_name :''"
              >
                {{ globalHelper.pDetails.company_name }}
              </h6>
              <div class="action">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt=""
                       (click)="globalHelper.goToDetails(globalHelper.pDetails.companyId,'company')"
                  >
                </div>
                <div class="add-to-list">
                  <img class="vts-svg-primary cursor"
                       [src]="!globalHelper.isSaved.isCmpSaved ? 'assets/images/svg/addList.svg' :
                          'assets/images/svg/addList-fill.svg'"
                       alt=""
                       (click)="addToCmpList()"
                       [tooltip]="ls.data.company.toolTip.addToList"
                       placement="top"
                  >
                  <div class="vts-border-theme save-contact"
                       *ngIf="isCmpListAdded"
                  >
                    <div class="form-group">
                      <ng-select #cmpListSelect
                                 bindLabel="value"
                                 bindValue="value"
                                 [addTag]="globalHelper.addList"
                                 [addTagText]="ls.data.company.createNewList"
                                 [items]="globalHelper.cmpListData.cmpListDataAvl"
                                 (change)="globalHelper.onListChange('company')"
                                 [(ngModel)]="globalHelper.selectedListType.selectedCmpList"
                                 [multiple]="true" [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                      ></ng-select>
                    </div>
                    <div class="action-buttons">
                      <span class="vts-theme-bg vts-border-theme fill-btn"
                            (click)="saveCompanyList(globalHelper.selectedListType.selectedCmpList)"
                      >
                        {{ ls.data.global.buttons.save }}
                      </span>
                      <span class="vts-border-theme vts-icon-color"
                            (click)="onListCancel('company')"
                      >
                        {{ ls.data.global.buttons.cancel }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cmp-profile-bottom">
          <div class="cmp-details-list">
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/copy.svg"
                     alt=""
                >
              </div>
              <div class="info-desc">
                <span class="vts-icon-color cmp-name"
                      (click)="goToExternalLink(globalHelper.pDetails.website,false)"
                >
                  {{ globalHelper.pDetails.website }}
                </span>
                <span *ngIf="globalHelper.pDetails.company_li_url">
                  |
                  <img src="assets/images/svg/linkedin.svg"
                       alt=""
                       (click)="goToExternalLink(globalHelper.pDetails.company_li_url,false)"
                  >
                </span>
                <span *ngIf="globalHelper.pDetails.company_facebook_url">
                  |
                  <img src="assets/images/svg/facebook.svg"
                       alt=""
                       (click)="goToExternalLink(globalHelper.pDetails.company_facebook_url,false)"
                  >
                </span>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/about.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.contact.about }}</span>
                <p class="cmp-desc"
                   *ngIf="globalHelper.pDetails.company_description"
                >
                  {{
                    globalHelper.isExpanded.open ? globalHelper.pDetails.company_description :
                      globalHelper.isExpanded.open ? globalHelper.pDetails.company_description :
                        globalHelper.pDetails.company_description.slice(0, 100)
                  }}
                  <a class="vts-icon-color text-to-expand"
                     (click)="globalHelper.toggleDescription()"
                     *ngIf="globalHelper.pDetails.company_description !== '' && globalHelper.pDetails.company_description.length > 100"
                  >
                    {{ globalHelper.isExpanded.open ? ls.data.global.misc.viewLess : ls.data.global.misc.viewMore }}
                  </a>
                </p>
                <p class="cmp-desc"
                   *ngIf="!globalHelper.pDetails.company_description"
                >
                  {{ ls.data.global.misc.notAvailable }}
                </p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/founded.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.foundIn }}</span>
                <p class="val-count">
                  {{ globalHelper.pDetails.founded ? globalHelper.pDetails.founded : ls.data.global.misc.notAvailable }}
                </p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/headquarter.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.headquarterLocation }}</span>
                <p class="val-count">
                  {{ globalHelper.pDetails.hq_location ? globalHelper.pDetails.hq_location : ls.data.global.misc.notAvailable }}
                </p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/empSize.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.employeeSize }}</span>
                <p class="val-count">
                  {{ globalHelper.pDetails.emp_range ? globalHelper.pDetails.emp_range : ls.data.global.misc.notAvailable }}
                </p>
              </div>
              <div class="vts-icon-color val-count show-employees"
                   (click)="globalHelper.toggleFill('employee', true)"
              >
                {{ ls.data.company.showEmployees }}
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/revenue.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.annualRevenueRange }}</span>
                <p class="val-count">
                  {{ globalHelper.pDetails.rev_range ? globalHelper.pDetails.rev_range : ls.data.global.misc.notAvailable }}
                </p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/industry.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.industry }}</span>
                <p class="val-count">
                  {{ globalHelper.pDetails.parentDept ? globalHelper.pDetails.parentDept : ls.data.global.misc.notAvailable }}
                </p>
              </div>
            </div>
            <div class="cmp-list-item">
              <div class="infographics">
                <img class="vts-svg-secondary"
                     src="assets/images/svg/specialities.svg"
                     alt=""
                >
              </div>
              <div class="vts-tag-color info-desc">
                <span>{{ ls.data.company.specialities }}</span>
                <div class="chip-sets">
                  <ng-container *ngIf="globalHelper.pDetails.specialty.length">
                    <div class="vts-chipset chip-set"
                         *ngFor="let item of globalHelper.pDetails.specialty"
                    >
                      {{ item }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!globalHelper.pDetails.specialty.length">
                    <p class="val-count">{{ ls.data.global.misc.notAvailable }}</p>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="redirect-to-emp text-center">
            <div (click)="globalHelper.goToDetails(globalHelper.pDetails.contactId,'contact')"
                 class="redirect-pointer"
            >
              <strong>{{ ls.data.contact.moreContactDetails }}</strong>
              <img class="vts-svg-primary"
                   src="assets/images/svg/redirect.svg"
                   alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- contact not found  -->
  <ng-container *ngIf="!isContactAvailable && isLoaded">
    <div class="custom-card">
      <div class="profile-card">
        <div class="profile-top">
          <div class="profile-logo">
            <ng-container *ngIf="linkedInScrappedData.contact.contactLogo &&
                             linkedInScrappedData.contact.contactLogo !== linkedinContactPlaceholder; else initials"
            >
              <img [src]="linkedInScrappedData.contact.contactLogo" alt="">
            </ng-container>
            <ng-template #initials>
              <div class="initials"
                   [ngStyle]="globalHelper.pDetails.avatarStyle"
              >
                {{ globalHelper.getInitials(linkedInScrappedData.contact.contactName) }}
              </div>
            </ng-template>
          </div>
          <div class="profile-content">
            <div class="profile-info">
              <h6 #FNM
                  class="overflow-text"
                  [tooltip]="FNM.offsetWidth < FNM.scrollWidth ?
                       linkedInScrappedData.contact.contactName :''"
                  placement="top"
              >
                {{
                  linkedInScrappedData.contact.contactName ?
                    linkedInScrappedData.contact.contactName :
                    'Not Available'
                }}
              </h6>
              <div class="action no-cursor">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src=" assets/images/svg/redirect.svg"
                       alt=""
                  >
                </div>
                <div class="add-to-list">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/addList.svg"
                       alt=""
                  >
                </div>
              </div>
            </div>
            <p #JTE
               class="job-title overflow-text"
               [tooltip]="JTE.offsetWidth < JTE.scrollWidth ?
                    linkedInScrappedData.contact.experience[0].jobTitle : ''"
               placement="bottom"
            >
              {{
                linkedInScrappedData.contact.experience[0].jobTitle ?
                  linkedInScrappedData.contact.experience[0].jobTitle :
                  ls.data.global.misc.notAvailable
              }}
            </p>
          </div>
        </div>
        <div class="profile-bottom">
          <div class="handle-contact-view">
            <div class="vts-theme-button custom-btn disabled">
              {{ ls.data.contact.showContact }}
            </div>
          </div>
          <div class="cnt-details-list">
            <div class="cnt-details">
              <div class="cnt-title">
                {{ ls.data.contact.officeEmail }}
              </div>
              <div class="cnt-data">
                <div class="p-details">
                  <p>{{ ls.data.contact.stayTunedDataComingSoon }}</p>
                </div>
              </div>
            </div>
            <div class="cnt-details">
              <div class="cnt-title">
                {{ ls.data.contact.officePhone }}
              </div>
              <div class="cnt-data">
                <div class="p-details">
                  <p>{{ ls.data.contact.stayTunedDataComingSoon }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-card mb-0 custom-height">
      <div class="profile-card">
        <div class="profile-top">
          <div class="profile-logo">
            <img [src]="linkedInScrappedData.contact.experience[0].companyLogo ?
                    linkedInScrappedData.contact.experience[0].companyLogo :
                    'assets/images/png/company-placeholder.png'"
                 alt=""
            >
          </div>
          <div class="profile-content">
            <div class="profile-info">
              <h6 #CMN
                  class="overflow-text"
                  [tooltip]="CMN.offsetWidth < CMN.scrollWidth ?
                  linkedInScrappedData.contact.experience[0].companyName :''"
                  placement="bottom"
              >
                {{ linkedInScrappedData.contact.experience[0].companyName }}
              </h6>
              <div class="action no-cursor">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt=""
                  >
                </div>
                <div class="add-to-list">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/addList.svg"
                       alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cmp-profile-bottom">
          <div class="vts-border-theme not-found text-center">
            <img class="vts-svg-primary"
                 src="assets/images/svg/not-found.svg"
                 alt=""
            >
            <p>{{ ls.data.company.noCompanyDataFoundYetWe }}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- skeleton -->
  <ng-container *ngIf="!isLoaded">
    <div class="skeleton">
      <div class="custom-skeleton">
        <div class="p-d-flex p-mb-3">
          <div class="circle skeleton-effect p-mr-2"
               size="4rem"
          ></div>
          <div class="stripes">
            <div class="p-mb-2 skeleton-effect"
                 *ngFor="let _ of [1,2]"
            ></div>
          </div>
        </div>
        <div class="stripes fullStripes">
          <div class="p-mb-2 skeleton-effect"
               *ngFor="let _ of [1,2,3,4,5,6,7]"
          ></div>
        </div>
      </div>
      <div class="custom-skeleton">
        <div class="p-d-flex p-mb-3">
          <div class="circle skeleton-effect p-mr-2"
               size="4rem"
          ></div>
          <div class="stripes">
            <div class="p-mb-2 skeleton-effect"
                 *ngFor="let _ of [1,2]"
            ></div>
          </div>
        </div>
        <div class="stripes full2Stripes">
          <div class="p-mb-2 skeleton-effect"
               *ngFor="let _ of [1,2,3,4,5,6,7,8]"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
