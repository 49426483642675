<div class="toast-messages">
    <div class="saved-list"
         [ngClass]="{'saved-list-error': globalHelper.toastMessage.type}"
    >
        <div class="saved-list-left"
             [ngClass]="{'toast-message': globalHelper.toastMessage.type}"
        >
            <img [src]="globalHelper.toastMessage.type ? 'assets/images/png/error.png' : 'assets/images/png/circle.png'"
                 alt=""
            >&nbsp;
            <strong>
                {{ globalHelper.toastMessage.message }}&nbsp;&nbsp;
            </strong>
        </div>
        <div class="saved-list-right">
            <i class="fa fa-times"
               aria-hidden="true"
               (click)="globalHelper.closeNotify()"
            ></i>
        </div>
    </div>
</div>
