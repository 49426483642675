import { Component } from '@angular/core';
import { goToExternalLink } from '../../global.helper';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../dynamic/language.service';

@Component({
  selector: 'app-extension-pop-up',
  templateUrl: './extension-pop-up.component.html',
  styleUrls: ['./extension-pop-up.component.less'],
})
export class ExtensionPopUpComponent {

  constructor(public ls: LanguageService) {
  }

  triggerParentBodyClick() {
    window.close();
  }

  protected readonly environment = environment;
  protected readonly goToExternalLink = goToExternalLink;
}
