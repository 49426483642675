import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AppComponent } from 'src/app/app.component';
import { GlobalHelper, goToExternalLink } from '../../global.helper';
import { LanguageService } from 'src/app/dynamic/language.service';
import { ILinkedInScrappedData } from '../../global.types';
import { ICompanyProfile, ILinkedInPayload } from '../../services/common.service.types';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.less', '../contact/contact.component.less'],
})
export class CompanyComponent implements OnInit {

  @Input() noProtocolLinkedInUrl: string = '';
  @Input() isLinkedInUrl: boolean = false;
  @Input('linkedInScrappedData') linkedInScrappedData: ILinkedInScrappedData;
  @ViewChild('cntListSelect') cntListSelect!: NgSelectComponent;
  @ViewChild('cmpListSelect') cmpListSelect!: NgSelectComponent;

  contactData: any;

  isLoaded: boolean = false;
  isCmpListAdded: boolean = false;
  isCompanyAvailable: boolean = false;

  isCntListAdded: boolean[] = new Array(this.globalHelper.employeeData.cntData.length).fill(false);

  constructor(
    public commonService: CommonService,
    public globalHelper: GlobalHelper,
    public appComponent: AppComponent,
    public ls: LanguageService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn && !this.authService.planExpired) {
      this.getLinkedInProfileData();
      this.initMethods();
    }
  }

  initMethods() {
    this.globalHelper.getDefaultCntList();
    this.globalHelper.getDefaultCmpList();
    this.globalHelper.getDefaultList();
  }

  getLinkedInProfileData() {
    if (!this.noProtocolLinkedInUrl) {
      return;
    }
    this.isLoaded = true;
    const cmpName = this.linkedInScrappedData.contact.experience[0]?.companyName || this.linkedInScrappedData.company.companyName;
    const body: ILinkedInPayload = {
      linkedIn: this.noProtocolLinkedInUrl,
      isView: false,
      fullName: '',
      homeLocation: '',
      companyName: cmpName,
      jobTitle: '',
      workLocation: '',
      workDuration: '',
      experience: [],
      cmpLiUrl: this.linkedInScrappedData.company.companyLiUrl,
      cmpLogoUrl: this.linkedInScrappedData.company.companyLogoUrl,
      contactLogo: '',
      contactLiUrl: this.linkedInScrappedData.tabUrl,
      linkedInHTML: this.linkedInScrappedData.linkedinHTML,
      isCmpUrl: true,
      isContact: false,
      chromeExtCredits: true,
    };
    this.commonService.getCompanyProfile(body)
      .then(async(profileData: ICompanyProfile) => {
        if (!profileData) {
          return;
        }
        this.isCompanyAvailable = true;
        const companyLogoUrl: any = await this.globalHelper.getCompanyLogos(profileData.domainId) || this.linkedInScrappedData.company.companyLogoUrl;
        this.globalHelper.pDetails = {
          ...this.globalHelper.pDetails,
          company_name: this.getCompanyName(profileData, this.linkedInScrappedData?.company?.companyName),
          companyId: profileData.companyId || '',
          parentDept: profileData.parentDept || 'Not Available',
          emp_range: profileData.empRange || 'Not Available',
          rev_range: profileData.revRange || 'Not Available',
          hq_location: profileData.hqLocation || 'Not Available',
          company_li_url: profileData.companyLiUrl || '',
          company_facebook_url: profileData.companyFacebookUrl || '',
          cmpLogo: companyLogoUrl,
          isSubExpire: profileData.isSubExpire || '',
          website: profileData.website || 'Not Available',
          specialty: Array.from(new Set(profileData.specialty)) || 'Not Available',
          founded: profileData.founded || 'Not Available',
          company_description: profileData.companyDescription || '',
        };
        if (this.globalHelper.pDetails.companyId) {
          await this.fetchCompanyDetails(this.globalHelper.pDetails.companyId);
        }
      })
      .catch((error: any) => {
        this.globalHelper.toastMessages(this.ls.takeErrorMsg(error?.error?.langCode), 'error');
      })
      .finally(() => {
        this.isLoaded = false;
      });
  }

  getCompanyName(profileData: ICompanyProfile, linkedInCompanyName: string) {
    return (profileData.companyName && linkedInCompanyName)
      ? (profileData.companyName === linkedInCompanyName ? profileData.companyName : linkedInCompanyName)
      : profileData.companyName;
  }

  async fetchCompanyDetails(companyId: string) {
    this.globalHelper.getCmpList(companyId);
    this.globalHelper.getListSave('company', companyId);
    await this.globalHelper.getEmployeeList(0, 5);
  }

  onListCancel(type: 'contact' | 'company', i?: any) {
    this.isCntListAdded[i] = !this.isCntListAdded[i];
    const selectModel = type === 'contact' ? this.cntListSelect : this.cmpListSelect;
    if (selectModel) {
      selectModel.clearModel();
    }
    if (type === 'company') {
      this.isCmpListAdded = false;
    }
  }

  saveContactList(saveCntList: any, i: any, contactId: string, companyId: string) {
    const isDefaultListSelected = this.globalHelper.defaultList.defaultSelectedCntList !== undefined;
    this.isCntListAdded[i] = isDefaultListSelected ? false : !this.isCntListAdded[i];
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
    if (saveCntList.length) {
      const body = {
        'indexIds': [contactId],
        'moduleType': 'mylist',
        'type': 'contact',
        'listName': saveCntList,
      };
      this.commonService.saveCntList(body)
        .then(() => {
          this.globalHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          if (companyId) {
            this.globalHelper.getEmployeeList(0, 5)
              .then();
          }
        })
        .catch(() => {
          this.globalHelper.toastMessages(this.ls.data.contact.contactAlreadyExist, 'error');
        })
        .finally(() => {
          this.onListCancel('contact');
        });
    }
  }

  saveCompanyList(saveCmpList: any) {
    this.isCmpListAdded = false;
    if (saveCmpList.length) {
      const body = {
        indexIds: [this.globalHelper.pDetails.companyId],
        moduleType: 'mylist',
        type: 'account',
        listName: saveCmpList,
      };

      this.commonService.saveCmpList(body)
        .then(() => {
          this.globalHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          this.globalHelper.getListSave('company', this.globalHelper.pDetails.companyId);
        })
        .catch(() => {
          this.globalHelper.toastMessages(this.ls.data.company.companyAlreadyExist, 'error');
        })
        .finally(() => {
          this.onListCancel('company');
        });
    }

    this.cmpListSelect?.clearModel();
  }

  viewContact(contactId: string, toggleCollapse: boolean = false): void {
    const handleData = (contactData: any) => {
      const previousContactId = this.contactData?.contactId;
      this.contactData = contactData;
      if (previousContactId && previousContactId !== contactId) {
        const previousContact = this.globalHelper.employeeData.cntData.find((contact: any) => contact.contactId === previousContactId);
        if (previousContact) {
          previousContact.isContactCollapse = true;
          previousContact.alreadyView = true;
        }
      }
      this.globalHelper.employeeData.cntData.forEach((contact: any) => {
        contact.isContactCollapse = toggleCollapse ? contact.contactId === contactId ? !contact.isContactCollapse : false : contact.contactId === contactId;
        contact.alreadyView = contact.contactId === contactId || contact.alreadyView;
      });
    };
    const handleError = (error: any) => {
      this.globalHelper.toastMessages(this.ls.takeErrorMsg(error?.error?.langCode), 'error');
    };
    this.commonService.contactView(contactId)
      .then(handleData)
      .catch(handleError);
  }

  addToCmpList(): void {
    this.globalHelper.getCmpList(this.globalHelper.pDetails.companyId);
    const defaultCmpList = this.globalHelper.defaultList.defaultSelectedCmpList;
    if (defaultCmpList !== undefined) {
      if (this.globalHelper.pluginSettings.askWhereToSave) {
        this.isCmpListAdded = !this.isCmpListAdded;
        this.globalHelper.selectedListType.selectedCmpList = [defaultCmpList];
      } else {
        this.saveCompanyList([defaultCmpList]);
        this.globalHelper.getListSave('company', this.globalHelper.pDetails.companyId);
      }
    } else {
      this.isCmpListAdded = !this.isCmpListAdded;
    }

    if (this.cmpListSelect) {
      this.cmpListSelect.clearModel();
    }
  }

  addToCntList(contactId: string, i: number, companyId: string): void {
    this.globalHelper.getCntList(contactId);
    const { defaultSelectedCntList } = this.globalHelper.defaultList;
    const { askWhereToSave } = this.globalHelper.pluginSettings;
    this.isCntListAdded.fill(false);
    this.isCntListAdded[i] = true;
    if (defaultSelectedCntList !== undefined) {
      if (askWhereToSave) {
        this.globalHelper.selectedListType.selectedCntList = [defaultSelectedCntList];
      } else {
        this.saveContactList([defaultSelectedCntList], i, contactId, companyId);
        this.globalHelper.getEmployeeList(0, 5)
          .then();
      }
    }
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
  }

  protected readonly goToExternalLink = goToExternalLink;
}

