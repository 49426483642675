<div class="main-body">
  <!-- list top -->
  <div class="custom-card">
    <div class="profile-card">
      <div class="vts-tag-color panel-title">
        {{ ls.data.activities.activity }}
      </div>
      <div class="custom-tabs">
        <ul class="vts-border-theme list-nav">
          <li *ngFor="let item of items"
              (click)="onItemClick(item)"
              [ngClass]="{'vts-theme-bg active': item.isActive}"
          >
            <img [src]="item.imgPath"
                 alt=""
                 [ngClass]="{'vts-svg-primary': !item.isActive}"
            >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- recent -->
  <ng-container *ngIf="activeItem && activeItem.name === 'Recent' && activeItem.isActive">
    <div class="custom-card mb-0 recent-tab result-body"
         *ngIf="!globalHelper.listData.cndDataLoader && globalHelper.listData.data && globalHelper.listData.data.length"
    >
      <div class="profile-card">
        <div class="cmp-profile-bottom">
          <div class="emp-details-list recent-list-body"
               (scroll)="onPageScroll($event)"
          >
            <div class="emp-list-item"
                 *ngFor="let employee of globalHelper.listData.data;let index = index"
            >
              <!-- contact -->
              <ng-container *ngIf="employee.type === 'contact'">
                <div class="emp-list-top">
                  <div class="emp-list-top-left">
                    <div class="employee-logo">
                      <ng-container *ngIf="employee.imageUrl; else initials">
                        <img [src]="employee.imageUrl" alt="">
                      </ng-container>
                      <ng-template #initials>
                        <div class="initials"
                             [ngStyle]="employee.avatarStyle"
                        >
                          {{ globalHelper.getInitials(employee.firstname + ' ' + employee.lastname) }}
                        </div>
                      </ng-template>
                    </div>
                    <div class="employee-info">
                      <div class="employee-title">
                        <h4 #FNM
                            class="vts-icon-color overflow-text"
                            [tooltip]="FNM.offsetWidth < FNM.scrollWidth ? employee.firstname + ' ' + employee.lastname : ''"
                            placement="bottom"
                            (click)="goToDetails(employee.id,'contact')"
                        >
                          {{ employee.firstname + ' ' + employee.lastname }}
                        </h4>
                        <ng-container *ngIf="employee?.contact_li_url">
                          <img class="social-icon"
                               src="assets/images/svg/linkedin.svg"
                               alt=""
                               (click)="goToExternalLink(employee.contact_li_url,false)"
                          >
                        </ng-container>
                        <ng-container *ngIf="employee?.facebook_url">
                          <img class="social-icon"
                               src="assets/images/svg/facebook.svg"
                               alt=""
                               (click)="goToExternalLink(employee.facebook_url,false)"
                          >
                        </ng-container>
                      </div>
                      <p #JTE
                         class="overflow-text"
                         [tooltip]="JTE.offsetWidth < JTE.scrollWidth ? employee.job_title :''"
                         placement="bottom"
                      >
                        {{ employee.job_title ? employee.job_title : "" }}
                      </p>
                    </div>
                  </div>
                  <div class="emp-list-top-right">
                    <div class="action">
                      <div class="add-to-list">
                        <img class="vts-svg-primary cursor"
                             [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                             alt=""
                             (click)="getCntList(employee.id, index)"
                             [tooltip]="ls.data.company.toolTip.addToList"
                             placement="top"
                        >
                        <div class="vts-border-theme save-contact"
                             *ngIf="isCntListAdded[index]"
                        >
                          <div class="form-group">
                            <ng-select #cntListSelect
                                       class="vts-border-theme"
                                       bindValue="value"
                                       [multiple]="true"
                                       [addTag]="globalHelper.addList"
                                       [addTagText]="ls.data.company.createNewList"
                                       (change)="globalHelper.onListChange('contact')"
                                       [(ngModel)]="globalHelper.selectedListType.selectedCntList"
                                       [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                       [items]="globalHelper.cntListData.cntListDataAvl" bindLabel="value"
                            ></ng-select>
                          </div>
                          <div class="action-buttons">
                              <span class="vts-theme-bg vts-border-theme fill-btn"
                                    (click)="saveContactList(globalHelper.selectedListType.selectedCntList, index, employee.id)"
                              >
                                {{ ls.data.global.buttons.save }}
                              </span>
                            <span class="vts-border-theme vts-icon-color"
                                  (click)="onListCancel('contact', index)"
                            >
                                {{ ls.data.global.buttons.cancel }}
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="emp-list-bottom">
                  <div class="emp-list-bottom-left">
                    <div class="vts-green-color-border emp-details">
                      <div #item
                           class="contact-details"
                           [ngClass]="{
                              'green-contact': employee.alreadyView && (employee.emailAddress || employee.phoneOffice || employee.phoneDirect),
                              'not-available': !employee.emailAddress && !employee.phoneOffice && !employee.phoneDirect,
                              'disabled': employee.isContactCollapse
                           }"
                           (click)="handleContactView(employee.id, employee.isContactCollapse)"
                           (mouseenter)="globalHelper.showHover(item)"
                           (mouseleave)="globalHelper.hideHover(item)"
                      >
                        <div class="details-wrapper">
                          <img class="vts-svg-primary"
                               src="assets/images/svg/xd-email.svg"
                               alt=""
                               placement="top"
                               [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                    ls.data.company.workEmail : ''"
                               [ngClass]="{
                                   'item-not-available': !employee.emailAddress,
                                   'item-green': employee.alreadyView && employee.emailAddress
                               }"
                          >
                          <span class="divider">|</span>
                          <img class="vts-svg-primary"
                               src="assets/images/svg/xd-office-phone.svg"
                               alt=""
                               placement="top"
                               [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.phoneOffice : ''"
                               [ngClass]="{
                                   'item-not-available': !employee.phoneOffice,
                                   'item-green': employee.alreadyView && employee.phoneOffice
                               }"
                          >
                          <span class="divider">|</span>
                          <img class="vts-svg-primary"
                               src="assets/images/svg/xd-cell-phone.svg"
                               alt=""
                               placement="top"
                               [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.directPhone : ''"
                               [ngClass]="{
                                 'item-not-available': !employee.phoneDirect,
                                 'item-green': employee.alreadyView && employee.phoneDirect
                               }"
                          >
                        </div>
                        <div class="show-details"
                             *ngIf="globalHelper.hoveredItem === item &&
                                (employee.emailAddress || employee.phoneOffice || employee.phoneDirect) &&
                                !employee.alreadyView"
                             placement="right"
                             [tooltip]="!employee.alreadyView ? ls.data.global.toolTip.thisCostOneCredit : ''"
                        >
                          <p>{{ ls.data.actions.showDetails }}</p>
                        </div>
                      </div>
                      <div class="hide-link"
                           *ngIf="employee.isContactCollapse"
                           (click)="handleContactView(employee.id, employee.isContactCollapse)"
                      >
                        {{ ls.data.actions.hide }}
                      </div>
                    </div>
                    <div class="emp-details-view"
                         *ngIf="employee.isContactCollapse && contactProfile"
                    >
                      <ul>
                        <li [ngClass]="{'na': !contactProfile.emailAddress}">
                          <p class="label">{{ ls.data.company.workEmail }}</p>
                          :
                          <app-user-feedback [attributeValue]="contactProfile.emailAddress"
                                             [contactId]="contactProfile.contactId"
                                             [attributeName]="'email'"
                                             [isContactView]="true"
                                             [isListView]="true"
                          ></app-user-feedback>
                        </li>
                        <li [ngClass]="{'na': !contactProfile.phoneOffice}">
                          <p class="label">{{ ls.data.company.phoneOffice }}</p>
                          :
                          <app-user-feedback [attributeValue]="contactProfile.phoneOffice"
                                             [contactId]="contactProfile.contactId"
                                             [attributeName]="'phoneOffice'"
                                             [isContactView]="true"
                                             [isListView]="true"
                          ></app-user-feedback>
                        </li>
                        <li [ngClass]="{'na': !contactProfile.phoneDirect}">
                          <p class="label">{{ ls.data.company.directPhone }}</p>
                          :
                          <app-user-feedback [attributeValue]="contactProfile.phoneDirect"
                                             [contactId]="contactProfile.contactId"
                                             [attributeName]="'phoneDirect'"
                                             [isContactView]="true"
                                             [isListView]="true"
                          ></app-user-feedback>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- company -->
              <ng-container *ngIf="employee.type === 'company'">
                <div class="emp-list-top">
                  <div class="emp-list-top-left">
                    <div class="employee-logo">
                      <img *ngIf="employee.imageUrl"
                           [src]="employee.imageUrl"
                           alt=""
                      >
                      <img *ngIf="!employee.imageUrl"
                           src="assets/images/png/company-placeholder.png"
                           alt=""
                      >
                    </div>
                    <div class="employee-info">
                      <div class="employee-title">
                        <h4 #CNM
                            class="vts-icon-color overflow-text"
                            [tooltip]="CNM.offsetWidth < CNM.scrollWidth ? employee.company_name : ''"
                            placement="bottom"
                            (click)="goToDetails(employee.companyid,'company')"
                        >
                          {{ employee.company_name ? employee.company_name : '' }}
                        </h4>
                        <ng-container *ngIf="employee?.company_li_url">
                          <img class="social-icon"
                               src="assets/images/svg/linkedin.svg"
                               alt=""
                               (click)="goToExternalLink(employee.company_li_url,false)"
                          >
                        </ng-container>
                        <ng-container *ngIf="employee?.company_facebook_url">
                          <img class="social-icon"
                               src="assets/images/svg/facebook.svg"
                               alt=""
                               (click)="goToExternalLink(employee.company_facebook_url,false)"
                          >
                        </ng-container>
                      </div>
                      <p #PDT
                         class="overflow-text"
                         [tooltip]="PDT.offsetWidth < PDT.scrollWidth ? employee.parentdept :''"
                         placement="bottom"
                      >
                        {{ employee.parentdept ? employee.parentdept : "" }}
                      </p>
                    </div>
                  </div>
                  <div class="emp-list-top-right">
                    <div class="action">
                      <div class="add-to-list">
                        <img class="vts-svg-primary cursor"
                             [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                             alt=""
                             (click)="getCmpList(employee.companyid, index)"
                             [tooltip]="ls.data.company.toolTip.addToList"
                             placement="top"
                        >
                        <div class="vts-border-theme save-contact"
                             *ngIf="isCmpListAdded[index]"
                        >
                          <div class="form-group">
                            <ng-select #cmpListSelect
                                       bindLabel="value"
                                       bindValue="value"
                                       [multiple]="true"
                                       [addTag]="globalHelper.addList"
                                       [addTagText]="ls.data.company.createNewList"
                                       [items]="globalHelper.cmpListData.cmpListDataAvl"
                                       (change)="globalHelper.onListChange('company')"
                                       [(ngModel)]="globalHelper.selectedListType.selectedCmpList"
                                       [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                            ></ng-select>
                          </div>
                          <div class="action-buttons">
                              <span class="vts-theme-bg vts-border-theme fill-btn"
                                    (click)="saveCompanyList(globalHelper.selectedListType.selectedCmpList, index, employee.companyid)"
                              >
                                {{ ls.data.global.buttons.save }}
                              </span>
                            <span class="vts-border-theme vts-icon-color"
                                  (click)="onListCancel('company', index)"
                            >
                                {{ ls.data.global.buttons.cancel }}
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="emp-list-bottom">
                  <div class="emp-list-bottom-left">
                    <div class="vts-green-color-border emp-details company">
                      <ul [ngClass]="{'viewed': employee.alreadyView}">
                        <li #HLE
                            [tooltip]="HLE.offsetWidth < HLE.scrollWidth ? employee.hq_location : ''"
                            placement="top"
                        >
                          {{ ls.data.company.hqPrefix + employee.hq_location }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- contact -->
  <ng-container *ngIf="activeItem && activeItem.name === 'Contact' && activeItem.isActive">
    <div class="custom-card mb-0 contact-tab result-body"
         *ngIf="!globalHelper.listData.cndDataLoader && globalHelper.listData.cntData && globalHelper.listData.cntData.length"
    >
      <div class="profile-card">
        <div class="cmp-profile-bottom">
          <div class="card-title mb-0">
            <div class="vts-border-theme action action-list">
              <div class="vts-border-theme save-contact">
                <div class="form-group">
                  <ng-select #cntDefaultListSelect
                             class="vts-border-theme"
                             bindLabel="label"
                             bindValue="label"
                             [multiple]="false"
                             [addTag]="globalHelper.addList"
                             [addTagText]="ls.data.company.createNewList"
                             [placeholder]="ls.data.settings.defaultList"
                             [items]="globalHelper.defaultListData.cntListData"
                             (change)="onDefaultListChange($event, 'contact')"
                             [(ngModel)]="globalHelper.defaultList.defaultSelectedCntList"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div class="vts-icon-color link"
                 (click)="goToExternalLink(environment.PRE_URL+'/list',true)"
            >
              {{ ls.data.employee.viewMore }}
              <img class="vts-svg-primary"
                   src="assets/images/svg/redirect.svg"
                   alt=""
              >
            </div>
          </div>
          <div class="emp-details-list contact-list-body"
               (scroll)="onPageScroll($event)"
          >
            <div *ngFor="let employee of globalHelper.listData.cntData;let index = index"
                 class="emp-list-item"
            >
              <div class="emp-list-top">
                <div class="emp-list-top-left">
                  <div class="employee-logo">
                    <ng-container *ngIf="employee.imageUrl; else initials">
                      <img [src]="employee.imageUrl" alt="">
                    </ng-container>
                    <ng-template #initials>
                      <div class="initials"
                           [ngStyle]="employee.avatarStyle"
                      >
                        {{ globalHelper.getInitials(employee.firstname + ' ' + employee.lastname) }}
                      </div>
                    </ng-template>
                  </div>
                  <div class="employee-info">
                    <div class="employee-title">
                      <h4 #FNL
                          class="vts-icon-color overflow-text"
                          [tooltip]="FNL.offsetWidth < FNL.scrollWidth ? employee.firstname + ' ' + employee.lastname : ''"
                          placement="bottom"
                          (click)="goToDetails(employee.id,'contact')"
                      >
                        {{ employee.firstname + ' ' + employee.lastname }}
                      </h4>
                      <ng-container *ngIf="employee?.contact_li_url">
                        <img class="social-icon"
                             src="assets/images/svg/linkedin.svg"
                             alt=""
                             (click)="goToExternalLink(employee.contact_li_url,false)"
                        >
                      </ng-container>
                      <ng-container *ngIf="employee?.facebook_url">
                        <img class="social-icon"
                             src="assets/images/svg/facebook.svg"
                             alt=""
                             (click)="goToExternalLink(employee.facebook_url,false)"
                        >
                      </ng-container>
                    </div>
                    <p #JTE
                       class="overflow-text"
                       [tooltip]="JTE.offsetWidth < JTE.scrollWidth ? employee.job_title :''"
                       placement="bottom"
                    >
                      {{ employee.job_title ? employee.job_title : "" }}
                    </p>
                  </div>
                </div>
                <div class="emp-list-top-right">
                  <div class="action">
                    <div class="add-to-list">
                      <img class="vts-svg-primary cursor"
                           [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                           alt=""
                           (click)="getCntList(employee.id, index)"
                           [tooltip]="ls.data.company.toolTip.addToList"
                           placement="top"
                      >
                      <div class="vts-border-theme save-contact"
                           *ngIf="isCntListAdded[index]"
                      >
                        <div class="form-group">
                          <ng-select class="vts-border-theme"
                                     bindLabel="value"
                                     bindValue="value"
                                     [multiple]="true"
                                     [addTag]="globalHelper.addList"
                                     [addTagText]="ls.data.company.createNewList"
                                     [items]="globalHelper.cntListData.cntListDataAvl"
                                     (change)="globalHelper.onListChange('contact')"
                                     [(ngModel)]="globalHelper.selectedListType.selectedCntList"
                                     [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                          ></ng-select>
                        </div>
                        <div class="action-buttons">
                            <span class="vts-theme-bg vts-border-theme fill-btn"
                                  (click)="saveContactList(globalHelper.selectedListType.selectedCntList, index, employee.id)"
                            >
                              {{ ls.data.global.buttons.save }}
                            </span>
                          <span class="vts-border-theme vts-icon-color"
                                (click)="onListCancel('contact', index)"
                          >
                              {{ ls.data.global.buttons.cancel }}
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="emp-list-bottom">
                <div class="emp-list-bottom-left">
                  <div class="vts-green-color-border emp-details">
                    <div #item
                         class="contact-details"
                         [ngClass]="{
                             'green-contact': employee.alreadyView && (employee.emailAddress || employee.phoneOffice || employee.phoneDirect),
                             'not-available': !employee.emailAddress && !employee.phoneOffice && !employee.phoneDirect,
                             'disabled': employee.isContactCollapse
                         }"
                         (mouseenter)="globalHelper.showHover(item)"
                         (mouseleave)="globalHelper.hideHover(item)"
                         (click)="handleContactView(employee.id, employee.isContactCollapse)"
                    >
                      <div class="details-wrapper">
                        <img class="vts-svg-primary"
                             src="assets/images/svg/xd-email.svg"
                             alt=""
                             placement="top"
                             [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.workEmail : ''"
                             [ngClass]="{
                                 'item-not-available': !employee.emailAddress,
                                 'item-green': employee.alreadyView && employee.emailAddress
                             }"
                        >
                        <span class="divider">|</span>
                        <img class="vts-svg-primary"
                             src="assets/images/svg/xd-office-phone.svg"
                             alt=""
                             placement="top"
                             [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                   ls.data.company.phoneOffice : ''"
                             [ngClass]="{
                                 'item-not-available': !employee.phoneOffice,
                                 'item-green': employee.alreadyView && employee.phoneOffice
                             }"
                        >
                        <span class="divider">|</span>
                        <img class="vts-svg-primary"
                             src="assets/images/svg/xd-cell-phone.svg"
                             alt=""
                             placement="top"
                             [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ? ls.data.company.directPhone : ''"
                             [ngClass]="{
                               'item-not-available': !employee.phoneDirect,
                               'item-green': employee.alreadyView && employee.phoneDirect
                             }"
                        >
                      </div>
                      <div class="show-details"
                           *ngIf="globalHelper.hoveredItem === item &&
                              (employee.emailAddress || employee.phoneOffice || employee.phoneDirect) &&
                              !employee.alreadyView"
                           [tooltip]="!employee.alreadyView ? ls.data.global.toolTip.thisCostOneCredit : ''"
                           placement="right"
                      >
                        <p>{{ ls.data.actions.showDetails }}</p>
                      </div>
                    </div>
                    <div class="hide-link"
                         *ngIf="employee.isContactCollapse"
                         (click)="handleContactView(employee.id, employee.isContactCollapse)"
                    >
                      {{ ls.data.actions.hide }}
                    </div>
                  </div>
                  <div class="emp-details-view"
                       *ngIf="employee.isContactCollapse && contactProfile"
                  >
                    <ul>
                      <li [ngClass]="{'na': !contactProfile.emailAddress}">
                        <p class="label">{{ ls.data.company.workEmail }}</p>
                        :
                        <app-user-feedback [attributeValue]="contactProfile.emailAddress"
                                           [contactId]="contactProfile.contactId"
                                           [attributeName]="'email'"
                                           [isContactView]="true"
                                           [isListView]="true"
                        ></app-user-feedback>
                      </li>
                      <li [ngClass]="{'na': !contactProfile.phoneOffice}">
                        <p class="label">{{ ls.data.company.phoneOffice }}</p>
                        :
                        <app-user-feedback [attributeValue]="contactProfile.phoneOffice"
                                           [contactId]="contactProfile.contactId"
                                           [attributeName]="'phoneOffice'"
                                           [isContactView]="true"
                                           [isListView]="true"
                        ></app-user-feedback>
                      </li>
                      <li [ngClass]="{'na': !contactProfile.phoneDirect}">
                        <p class="label">{{ ls.data.company.directPhone }}</p>
                        :
                        <app-user-feedback [attributeValue]="contactProfile.phoneDirect"
                                           [contactId]="contactProfile.contactId"
                                           [attributeName]="'phoneDirect'"
                                           [isContactView]="true"
                                           [isListView]="true"
                        ></app-user-feedback>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- company -->
  <ng-container *ngIf="activeItem && activeItem.name === 'Company' && activeItem.isActive">
    <div class="custom-card mb-0 company-tab result-body"
         *ngIf="!globalHelper.listData.cndDataLoader && globalHelper.listData.cmpData && globalHelper.listData.cmpData.length"
    >
      <div class="profile-card">
        <div class="cmp-profile-bottom">
          <div class="card-title mb-0">
            <div class="action action-list">
              <div class="vts-border-theme save-contact">
                <div class="form-group">
                  <ng-select #cmpDefaultListSelect
                             class="vts-border-theme"
                             bindLabel="label"
                             bindValue="label"
                             [multiple]="false"
                             [addTag]="globalHelper.addList"
                             [addTagText]="ls.data.company.createNewList"
                             [placeholder]="ls.data.settings.defaultList"
                             [items]="globalHelper.defaultListData.cmpListData"
                             (change)="onDefaultListChange($event, 'company')"
                             [(ngModel)]="globalHelper.defaultList.defaultSelectedCmpList"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div class="vts-icon-color link"
                 (click)="goToExternalLink(environment.PRE_URL+'/list',true)"
            >
              {{ ls.data.employee.viewMore }}
              <img class="vts-svg-primary"
                   src="assets/images/svg/redirect.svg"
                   alt=""
              >
            </div>
          </div>
          <div class="emp-details-list company-list-body"
               (scroll)="onPageScroll($event)"
          >
            <div class="emp-list-item"
                 *ngFor="let employee of globalHelper.listData.cmpData;let index = index"
            >
              <div class="emp-list-top">
                <div class="emp-list-top-left">
                  <div class="employee-logo">
                    <img [src]="employee.imageUrl ? employee.imageUrl : 'assets/images/png/company-placeholder.png'"
                         alt=""
                    >
                  </div>
                  <div class="employee-info">
                    <div class="employee-title">
                      <h4 #CNM
                          class="vts-icon-color overflow-text"
                          [tooltip]="CNM.offsetWidth < CNM.scrollWidth ? employee.company_name : ''"
                          placement="bottom"
                          (click)="goToDetails(employee.companyid,'company')"
                      >
                        {{ employee.company_name ? employee.company_name : '' }}
                      </h4>
                      <ng-container *ngIf="employee?.company_li_url">
                        <img class="social-icon"
                             src="assets/images/svg/linkedin.svg"
                             alt=""
                             (click)="goToExternalLink(employee.company_li_url,false)"
                        >
                      </ng-container>
                      <ng-container *ngIf="employee?.company_facebook_url">
                        <img class="social-icon"
                             src="assets/images/svg/facebook.svg"
                             alt=""
                             (click)="goToExternalLink(employee.company_facebook_url,false)"
                        >
                      </ng-container>
                    </div>
                    <p #PDM
                       class="overflow-text"
                       [tooltip]="PDM.offsetWidth < PDM.scrollWidth ? employee.parentdept :''"
                       placement="bottom"
                    >
                      {{ employee.parentdept ? employee.parentdept : "" }}
                    </p>
                  </div>
                </div>
                <div class="emp-list-top-right">
                  <div class="action">
                    <div class="add-to-list">
                      <img class="vts-svg-primary cursor"
                           [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                           alt=""
                           placement="top"
                           [tooltip]="ls.data.company.toolTip.addToList"
                           (click)="getCmpList(employee.companyid, index)"
                      >
                      <div class="vts-border-theme save-contact"
                           *ngIf="isCmpListAdded[index]"
                      >
                        <div class="form-group">
                          <ng-select [items]="globalHelper.cmpListData.cmpListDataAvl"
                                     bindLabel="value"
                                     bindValue="value"
                                     [multiple]="true"
                                     [addTag]="globalHelper.addList"
                                     [addTagText]="ls.data.company.createNewList"
                                     (change)="globalHelper.onListChange('company')"
                                     [(ngModel)]="globalHelper.selectedListType.selectedCmpList"
                                     [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                          ></ng-select>
                        </div>
                        <div class="action-buttons">
                          <span class="vts-theme-bg vts-border-theme fill-btn"
                                (click)="saveCompanyList(globalHelper.selectedListType.selectedCmpList, index, employee.companyid)"
                          >
                            {{ ls.data.global.buttons.save }}
                          </span>
                          <span class="vts-border-theme vts-icon-color"
                                (click)="onListCancel('company', index)"
                          >
                            {{ ls.data.global.buttons.cancel }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="emp-list-bottom">
                <div class="emp-list-bottom-left">
                  <div class="vts-green-color-border emp-details company">
                    <ul [ngClass]="{'viewed': employee.alreadyView}">
                      <li #HQL
                          [tooltip]="HQL.offsetWidth < HQL.scrollWidth ? employee.hq_location : ''"
                          placement="top"
                      >
                        {{ ls.data.company.hqPrefix + employee.hq_location }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- no data found -->
  <ng-container *ngIf="!globalHelper.listData.cndDataLoader && !globalHelper.listData.data.length">
    <div class="custom-card">
      <h1 class="coming-soon">{{ ls.data.employee.noDataFound }}</h1>
    </div>
  </ng-container>

  <!-- skeleton -->
  <ng-container *ngIf="globalHelper.listData.cndDataLoader">
    <div class="skeleton">
      <div class="custom-skeleton">
        <div class="stripes fullEmpStripes">
          <div class="p-mb-2 mb-3 skeleton-effect"
               *ngFor="let _ of [1,2,3,4,5,6,7,8,9,10]"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
