import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { COPY } from '../global.helper';
import { KeycloakService } from './keycloak.service';
import { environment } from '../../environments/environment';
import { emptyLinkedInScrappedData, ILinkedInScrappedData } from '../global.types';

@Injectable({ providedIn: 'root' })
export class CommunicationService {

  private linkedInScrappedDataSubject = new BehaviorSubject<ILinkedInScrappedData>(COPY(emptyLinkedInScrappedData));
  linkedInScrappedData$ = this.linkedInScrappedDataSubject.asObservable();

  constructor(private kcService: KeycloakService) {
  }

  init() {
    window.addEventListener('message', async(event) => {
      if (!event.data.type) {
        return;
      }

      switch (event.data.type) {
      case 'messageFromContentScript':
        this.setLinkedInScrappedData(event);
        break;
      case 'check-sso':
        this.checkSsoAndSendMessage()
          .then();
        break;
      }
    });
  }

  postMessageToParent(message: any) {
    window.parent.postMessage(message, '*');
  }

  setLinkedInScrappedData(event: any) {
    const {
      tabUrl,
      urlType,
      contact,
      company,
      entityType,
      linkedinHTML,
    } = event.data;

    const scrappedData = { ...this.linkedInScrappedDataSubject.getValue() };

    if (urlType === 'contact') {
      scrappedData.contact = contact;
    }
    if (urlType === 'company') {
      scrappedData.company = company;
    }
    Object.assign(scrappedData, {
      tabUrl,
      urlType,
      entityType,
      linkedinHTML,
    });

    this.linkedInScrappedDataSubject.next(scrappedData);
  }

  async checkSsoAndSendMessage() {
    const authenticated: boolean | undefined = await this.kcService.reinit();
    this.postMessageToParent({
      type: 'authenticated',
      authenticated,
      loginUrl: this.kcService.keycloak.createLoginUrl(),
    });
  }

  postLogoutMsg() {
    this.postMessageToParent({ type: 'logout' });
  }

  postPlanExpiredMsg() {
    this.postMessageToParent({
      type: 'plan-expired',
      planExpiredRedirectUrl: `${environment.PRE_URL}/plan-expiration`,
    });
  }
}
