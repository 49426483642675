import { Component, Input } from '@angular/core';
import { LanguageService } from '../../dynamic/language.service';
import { AuthService } from '../../services/auth.service';
import { GlobalHelper, goToExternalLink } from '../../global.helper';
import { CommunicationService } from '../../services/communication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent {

  @Input('isLinkedInUrl') isLinkedInUrl: boolean = false;

  constructor(
    public ls: LanguageService,
    public authService: AuthService,
    public globalHelper: GlobalHelper,
    public communicationService: CommunicationService,
  ) {
  }

  returnBack() {
    this.globalHelper.toggleFill(this.globalHelper.previousTab, false);
  }

  minimizeExt(action: string) {
    this.globalHelper.pluginSettings.minimize = action;
    this.communicationService.postMessageToParent(this.globalHelper.pluginSettings);
  }

  protected readonly goToExternalLink = goToExternalLink;
  protected readonly environment = environment;
}
