import { Component } from '@angular/core';
import { GlobalHelper } from '../../global.helper';
import { LanguageService } from '../../dynamic/language.service';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'app-non-profile',
  templateUrl: './non-profile.component.html',
  styleUrls: ['./non-profile.component.less'],
})
export class NonProfileComponent {

  constructor(
    public ls: LanguageService,
    public globalHelper: GlobalHelper,
    public communicationService: CommunicationService,
  ) {
  }

  searchProspects(clicked: string) {
    this.globalHelper.pluginSettings.clicked = clicked;
    this.communicationService.postMessageToParent(this.globalHelper.pluginSettings);
  }

}
