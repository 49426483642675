export interface VerifiedInfo {
  email: string;
  source: string;
  verified: boolean;
  verified_on: string | null;
}

export interface IContactProfile {
  website: string;
  empRange: string;
  revRange: string;
  contactId: string;
  companyId: string;
  seniority: string;
  department: string;
  phoneOffice: string;
  phoneDirect: string;
  contactLiUrl: string;
  emailAddress: string;
  personalEmail: string;
  verified: VerifiedInfo[];
  officeEmailVerificationType: string;
  phoneDirectVerificationType: string;
  phoneOfficeVerificationType: string;
}

export const emptyContactProfile: IContactProfile = {
  website: '',
  empRange: '',
  revRange: '',
  verified: [],
  contactId: '',
  seniority: '',
  companyId: '',
  department: '',
  phoneOffice: '',
  phoneDirect: '',
  emailAddress: '',
  contactLiUrl: '',
  personalEmail: '',
  officeEmailVerificationType: '',
  phoneDirectVerificationType: '',
  phoneOfficeVerificationType: '',
};
