import { Component, Input, OnChanges, Renderer2 } from '@angular/core';
import { COPY, GlobalHelper } from '../../global.helper';
import { CommonService } from '../../services/common.service';
import { LanguageService } from '../../dynamic/language.service';
import { emptyFeedbackPayload, IFeedbackOn, IFeedbackPayload, IGetFeedbackResponse } from '../../global.types';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.less'],
})
export class UserFeedbackComponent implements OnChanges {

  feedbackRequest: IFeedbackPayload = COPY(emptyFeedbackPayload);

  @Input('contactId') contactId: string = '';
  @Input('isListView') isListView: boolean = false;
  @Input('attributeValue') attributeValue: string = '';
  @Input('isContactView') isContactView: boolean = false;
  @Input('attributeName') attributeName: keyof IFeedbackOn;

  copyText: string = '';
  copiedText: string = '';

  reasons: string[] = [];
  userFeedbackRes: IGetFeedbackResponse[] = [];

  review: boolean = false;
  isCopied: boolean = false;
  showPopUp: boolean = false;
  openOption: boolean = false;
  haveFeedback: boolean = false;

  constructor(
    public ls: LanguageService,
    private renderer: Renderer2,
    public globalHelper: GlobalHelper,
    public commonService: CommonService,
  ) {
    this.copiedText = `<i class='fa fa-check-circle'></i> ` + this.ls.data.global.buttons.copied;
    this.copyText = this.ls.data.global.buttons.copy;
  }

  async ngOnChanges() {
    this.attributeValue = this.attributeValue || this.ls.data.global.misc.notAvailable;
    await this.getUserFeedback(this.contactId);
  }

  copy() {
    this.isCopied = true;
    const textarea: HTMLTextAreaElement = this.renderer.createElement('textarea');
    textarea.value = this.attributeValue;
    this.renderer.appendChild(document.body, textarea);
    textarea.select();
    document.execCommand('copy');
    this.renderer.removeChild(document.body, textarea);

    setTimeout(() => this.isCopied = false, 1000);
  }

  async getUserFeedback(contactId: string) {
    if (!contactId) {
      return;
    }
    this.review = false;
    this.haveFeedback = false;
    this.userFeedbackRes = await this.commonService.getUserFeedback([contactId]);
    if (this.userFeedbackRes.length) {
      const feedback = this.userFeedbackRes.find(item => item.attributeName === this.attributeName);
      this.review = feedback?.feedback === 'correct';
      this.haveFeedback = feedback?.attributeName === this.attributeName;
    }
  }

  userFeedback(type: 'correct' | 'incorrect', attributeName: string, contactId: string, attributeValue: string, changeFeedback?: boolean) {
    this.feedbackRequest = {
      ...COPY(emptyFeedbackPayload),
      isSelected: !changeFeedback,
      feedback: type,
      attributeName,
      attributeValue,
      contactId,
    };

    if (!changeFeedback) {
      if (type === 'incorrect') {
        this.showPopUp = true;
        this.feedbackRequest.reason = 'Select your reason';
        this.reasons = this.getReasons(attributeName);
      } else {
        this.submitFeedback()
          .then();
      }
    } else {
      this.submitFeedback()
        .then();
    }
  }

  async submitFeedback() {
    try {
      await this.commonService.saveUserFeedback(this.feedbackRequest);
      this.cancel();
      await this.getUserFeedback(this.contactId || this.feedbackRequest.contactId);
    } catch (error) {
      console.error('Error submitting feedback', error);
    }
  }

  cancel() {
    this.showPopUp = false;
    this.feedbackRequest = COPY(emptyFeedbackPayload);
  }

  selectReason(reason: string) {
    this.feedbackRequest.reason = reason;
    this.toggleOptions();
  }

  toggleOptions() {
    this.openOption = !this.openOption;
  }

  private getReasons(attributeName: string): string[] {
    return attributeName === 'email'
      ? ['Bounce Email', 'Email Seems Incorrect', 'Other']
      : ['Incorrect phone', 'Dead phone', 'Live phone-no answer', 'Other'];
  }

}
