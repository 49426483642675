export interface IRecentTabs {
  name: string;
  imgPath: string;
  isActive: boolean;
}

export const emptyRecentTabs: IRecentTabs = {
  name: 'Recent',
  isActive: true,
  imgPath: 'assets/images/svg/recent-tab.svg',
};
