import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { LanguageService } from 'src/app/dynamic/language.service';
import { COPY, GlobalHelper, goToExternalLink } from '../../global.helper';
import { CommunicationService } from '../../services/communication.service';
import { IContactProfile, ILinkedInPayload } from '../../services/common.service.types';
import { emptyLinkedInScrappedData, ILinkedInScrappedData, IScrappedExperienceData } from '../../global.types';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less'],
})
export class ContactComponent implements OnInit, OnDestroy {

  linkedInScrappedData: ILinkedInScrappedData = COPY(emptyLinkedInScrappedData);
  private subscription: Subscription = new Subscription();

  @ViewChild('cntListSelect') cntListSelect!: NgSelectComponent;
  @ViewChild('cmpListSelect') cmpListSelect!: NgSelectComponent;

  isLoaded: boolean = false;
  isContactView: boolean = false;
  isCntListAdded: boolean = false;
  isCmpListAdded: boolean = false;
  isFaCheckFaCopyEM: boolean = false;
  isFaCheckFaCopyPO: boolean = false;
  isContactAvailable: boolean = false;
  isFaCheckFaCopyShowEM: boolean = false;
  isFaCheckFaCopyShowPD: boolean = false;
  isFaCheckFaCopyShowPO: boolean = false;
  linkedinContactPlaceholder: string = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  constructor(
    public ls: LanguageService,
    public authService: AuthService,
    public globalHelper: GlobalHelper,
    public commonService: CommonService,
    public communicationService: CommunicationService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.communicationService.linkedInScrappedData$.subscribe((data: ILinkedInScrappedData) => {
        this.linkedInScrappedData = data;
      }),
    );
    if (!this.authService.loginUserDetails) {
      return;
    }
    if (this.authService.isLoggedIn && !this.authService.planExpired) {
      this.getLinkedInProfileData(false, 'onLoad')
        .then();
      this.initMethods();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initMethods(): void {
    this.globalHelper.getDefaultList();
    this.globalHelper.getDefaultCntList();
    this.globalHelper.getDefaultCmpList();
  }

  async getLinkedInProfileData(isView: boolean, type: string) {
    const {
      tabUrl,
      linkedinHTML,
    } = this.linkedInScrappedData;
    const experience = this.linkedInScrappedData.contact.experience?.[0] ?? {};
    const request: ILinkedInPayload = {
      isView,
      isCmpUrl: false,
      isContact: true,
      isLinkedinUrl: true,
      chromeExtCredits: true,
      linkedIn: tabUrl,
      contactLiUrl: tabUrl,
      linkedInHTML: linkedinHTML,
      jobTitle: experience.jobTitle ?? '',
      homeLocation: experience.location ?? '',
      workLocation: experience.location ?? '',
      workDuration: experience.duration ?? '',
      cmpLiUrl: experience.companyLiUrl ?? '',
      cmpLogoUrl: experience.companyLogo ?? '',
      companyName: experience.companyName ?? '',
      fullName: this.linkedInScrappedData.contact.contactName ?? '',
      experience: this.linkedInScrappedData.contact.experience ?? [],
      contactLogo: this.linkedInScrappedData.contact.contactLogo ?? '',
    };
    if (!request.companyName) {
      return;
    }
    try {
      const profileData = await this.commonService.getContactProfile(request);
      if (profileData) {
        this.isContactAvailable = true;
        await this.updateLinkedInData(profileData, type, isView);
      }
      setTimeout(() => (this.isLoaded = true), 300);
    } catch (error: any) {
      this.globalHelper.toastMessages(this.ls.takeErrorMsg(error?.error?.langCode), 'error');
      this.isLoaded = true;
    }
  }

  async updateLinkedInData(profileData: IContactProfile, type: string, isView: boolean) {
    const { contact } = this.linkedInScrappedData || {};
    const { experience } = contact;
    const linkedInExperience: IScrappedExperienceData = experience[0] ?? {};

    // Prefer `profileData` values; fallback to LinkedIn scraped data
    const companyName: string = profileData.companyName || linkedInExperience.companyName || '';
    const jobTitle: string = profileData.jobTitle || linkedInExperience.jobTitle || '';

    // Fetch company logo and contact image in parallel for efficiency
    const [companyLogo, contactImage]: [string, string] = await Promise.all([
      this.globalHelper.getCompanyLogos(profileData.domainId) as Promise<string>,
      this.globalHelper.getContactLogo(profileData.contactLogoId) as Promise<string>,
    ]);

    this.globalHelper.pDetails = {
      ...this.globalHelper.pDetails,
      fullName: `${profileData.firstName} ${profileData.lastName}`.trim(),
      contactLiUrl: profileData.contactLiUrl || '',
      companyId: profileData.companyId || '',
      work_email: profileData.emailAddress || 'Not Available',
      phone_office: profileData.phoneOffice || 'Not Available',
      phone_direct: profileData.phoneDirect || 'Not Available',
      id: profileData.id || '',
      contactId: profileData.contactId || '',
      imageUrl: contactImage,
      avatarStyle: this.globalHelper.getRandomAvatarStyle(),
      cmpLogo: companyLogo,
      parentDept: profileData.parentDept || 'Not Available',
      emp_range: profileData.empRange || 'Not Available',
      rev_range: profileData.revRange || 'Not Available',
      hq_location: profileData.hqLocation || 'Not Available',
      company_li_url: profileData.companyLiUrl || '',
      company_facebook_url: profileData.companyFacebookUrl || '',
      isSubExpire: profileData.isSubExpire,
      website: profileData.website || 'Not Available',
      specialty: Array.from(new Set(profileData.specialty)) || 'Not Available',
      founded: profileData.founded || 'Not Available',
      company_description: profileData.companyDescription || '',
      company_name: companyName,
      job_title: jobTitle,
    };

    this.isContactView = isView || profileData.alreadyViewCnt || false;
    this.updateCopyFlags(profileData);

    if (type === 'onLoad') {
      this.updateContactAndCompanyLists();
    }
  }

  updateCopyFlags(profileData: IContactProfile): void {
    this.isFaCheckFaCopyShowEM = !!profileData.emailAddress;
    this.isFaCheckFaCopyShowPD = !!profileData.phoneDirect;
    this.isFaCheckFaCopyShowPO = !!profileData.phoneOffice;
    this.isFaCheckFaCopyEM = !(profileData.emailAddress && profileData.emailAddress.includes('*'));
    this.isFaCheckFaCopyPO = !(profileData.phoneOffice && profileData.phoneOffice.includes('*'));
  }

  updateContactAndCompanyLists(): void {
    const {
      contactId,
      companyId,
    } = this.globalHelper.pDetails;
    if (contactId) {
      this.globalHelper.getCntList(contactId);
      this.globalHelper.getListSave('contact', contactId);
    }
    if (companyId) {
      this.globalHelper.getCmpList(companyId);
      this.globalHelper.getListSave('company', companyId);
    }
  }

  showDetails(): void {
    this.isContactInfoUnavailable() ? this.isContactView = true : this.getLinkedInProfileData(true, 'office_email')
      .then();
  }

  isContactInfoUnavailable(): boolean {
    const {
      work_email,
      phone_office,
      phone_direct,
    } = this.globalHelper.pDetails;
    return work_email === 'Not Available' && phone_office === 'Not Available' && phone_direct === 'Not Available';
  }

  onListCancel(type: 'contact' | 'company'): void {
    const listSelect: NgSelectComponent = type === 'contact' ? this.cntListSelect : this.cmpListSelect;
    if (listSelect) {
      listSelect.clearModel();
    }
    this.isCntListAdded = type === 'contact' ? false : this.isCntListAdded;
    this.isCmpListAdded = type === 'company' ? false : this.isCmpListAdded;
  }

  saveContactList(saveCntList: string[]): void {
    this.globalHelper.saveLists('contact', saveCntList, 'contactId', this.cntListSelect);
    this.onListCancel('contact');
  }

  saveCompanyList(saveCmpList: string[]): void {
    this.globalHelper.saveLists('company', saveCmpList, 'companyId', this.cmpListSelect);
    this.onListCancel('company');
  }

  addToCmpList(): void {
    this.globalHelper.getCmpList(this.globalHelper.pDetails.companyId);
    const defaultCmpList = this.globalHelper.defaultList.defaultSelectedCmpList;
    if (defaultCmpList !== undefined) {
      if (this.globalHelper.pluginSettings.askWhereToSave) {
        this.isCmpListAdded = !this.isCmpListAdded;
        this.globalHelper.selectedListType.selectedCmpList = [defaultCmpList];
      } else {
        this.isCmpListAdded = false;
        this.saveCompanyList([defaultCmpList]);
      }
    } else {
      this.isCmpListAdded = !this.isCmpListAdded;
    }
    this.cmpListSelect?.clearModel();
  }

  addToCntList(): void {
    this.globalHelper.getCntList(this.globalHelper.pDetails.contactId);
    const defaultCntList = this.globalHelper.defaultList.defaultSelectedCntList;
    if (defaultCntList !== undefined) {
      if (this.globalHelper.pluginSettings.askWhereToSave) {
        this.isCntListAdded = !this.isCntListAdded;
        this.globalHelper.selectedListType.selectedCntList = [defaultCntList];
      } else {
        this.isCntListAdded = false;
        this.saveContactList([defaultCntList]);
      }
    } else {
      this.isCntListAdded = !this.isCntListAdded;
    }
    this.cntListSelect?.clearModel();
  }

  protected readonly goToExternalLink = goToExternalLink;
}
