import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { COPY, GlobalHelper } from './global.helper';
import { AuthService } from './services/auth.service';
import { LanguageService } from './dynamic/language.service';
import { CommunicationService } from './services/communication.service';
import { emptyLinkedInScrappedData, ILinkedInScrappedData } from './global.types';

declare const chrome: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {

  linkedInScrappedData: ILinkedInScrappedData = COPY(emptyLinkedInScrappedData);

  isChromeTab: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    public ls: LanguageService,
    public authService: AuthService,
    public globalHelper: GlobalHelper,
    public communicationService: CommunicationService,
  ) {
  }

  ngOnInit() {
    if (!this.isChromeTab) {
      this.authService.handleAuthenticationStatus();
    }
    this.subscription.add(
      this.communicationService.linkedInScrappedData$.subscribe((data: ILinkedInScrappedData) => {
        this.linkedInScrappedData = data;
        this.globalHelper.toggleFill(this.linkedInScrappedData.urlType === 'contact' ? 'contact' : 'company', false);
      }),
    );
    this.promiscuousMode();
    this.checkIfChromeTab();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkIfChromeTab() {
    this.isChromeTab = !!(chrome && chrome.runtime && chrome.runtime.id);
  }

  promiscuousMode() {

    const linkedInScrappedData = this.dataCleaner(COPY(this.linkedInScrappedData));

    let {
      tabUrl,
      urlType,
      entityType,
      linkedinHTML,
      schemaVersion,
    } = linkedInScrappedData;

    if (!urlType || urlType === 'other') {
      return;
    }

    entityType = (entityType === 'contact' || entityType === 'company') ? 'info' : entityType;

    const request = {
      [entityType]: linkedInScrappedData[urlType],
      tabUrl,
      linkedinHTML,
      schemaVersion,
    };

    this.authService.promiscuousMode(urlType, entityType, request)
      .then();
  }

  dataCleaner(data: ILinkedInScrappedData) {
    return JSON.parse(
      JSON.stringify(data, (_, value) =>
        value === '' ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && value !== null && Object.keys(value).length === 0)
          ? undefined
          : value,
      ),
    );
  }

}
