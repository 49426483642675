<!--  chrome extension  -->
<ng-container *ngIf="isChromeTab">
  <div class="container px-lg-5 no-gutters">
    <div class="row">
      <div class="col-md-10">
        <!-- header -->
        <div class="row logo-t">
          <div class="col-6">
            <img src="assets/images/png/predictiv-logo.png"
                 alt="Predictiv"
                 loading="lazy"
                 class="pre-logo-w"
                 (click)="goToExternalLink(environment.PRE_URL,true)"
            >
          </div>
          <div class="col-6 mr-3 text-end">
            <div>
              <i class="fa fa-window-minimize d-inline-block sign-out"
                 aria-hidden="true"
                 [tooltip]="ls.data.global.buttons.close"
                 placement="bottom"
                 (click)="triggerParentBodyClick()"
              ></i>
            </div>
          </div>
        </div>
        <!-- body -->
        <div class="row g-0">
          <div class="col-lg-5 mb-lg-0">
            <div class="bg-white dash-wrapper">
              <div class="pl-2">
                <p class=" form-text way-to-work">
                  {{ ls.data.settings.platform }}
                </p>
                <div class="desc">
                  <div class="desc-left">
                    <img alt="Predictiv platform"
                         class="vts-svg-primary ico0"
                         src="assets/images/svg/dashboard.svg"
                    >
                    <span class="ml-2-dashboard linkedin-prospector cursor-point">
                  <a (click)="goToExternalLink(environment.PRE_URL+'/dashboard',true)">
                    {{ ls.data.dashboard.goToPredictiv }}
                  </a>
                </span>
                  </div>
                  <div class="desc-right">
                    <img (click)="goToExternalLink(environment.PRE_URL+'/dashboard',true)"
                         alt="redirect"
                         class="vts-svg-primary redirect"
                         src="assets/images/svg/xd-redirect.svg"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white dash-wrapper">
              <div class="pl-2">
                <p class=" form-text way-to-work">
                  {{ ls.data.dashboard.extension }}
                </p>
                <div class="desc">
                  <div class="desc-left">
                    <img src="assets/images/svg/linkedin.svg"
                         alt="linkedin"
                    >
                    <span class="ml-2-dashboard linkedin-prospector cursor-point">
                      <a (click)="goToExternalLink('https://www.linkedin.com/feed/',true)">
                        {{ ls.data.dashboard.linkedIn }}
                      </a>
                    </span>
                  </div>
                  <div class="desc-right">
                    <img (click)="goToExternalLink('https://www.linkedin.com/feed/',true)"
                         alt="redirect"
                         class="vts-svg-primary redirect"
                         src="assets/images/svg/xd-redirect.svg"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- non profile page -->
<ng-container *ngIf="!isChromeTab && communicationService.linkedInScrappedData.urlType === 'other' &&
                  authService.isLoggedIn"
>
  <header>
    <section class="header">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="logoDiv">
              <img class="logo"
                   src="assets/images/png/predictiv-logo.png"
                   alt="Predictiv"
                   (click)="goToExternalLink(environment.PRE_URL,true)"
              >
            </div>
          </div>
          <div class="col-6">
            <div class="header-actions">
              <div class="header-action">
                <i class="vts-icon-color fa fa-minus"
                   aria-hidden="true"
                   (click)="minimizeExt('minimize')"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </header>
  <section class="vts-gradient-background non-profile login-section">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-3">
          <p class="descTop">
            {{ ls.data.dashboard.discoverLimitLess }}
          </p>
        </div>
        <div class="col-12 text-center mb-2">
          <img class="login-logo"
               src="assets/images/svg/top.svg"
               alt="Predictiv"
               style="width: 100px;"
          >
        </div>
        <div class="col-12 mb-4">
          <div class="card bg-white card-corner">
            <div class="card-body p-1 ">
              <div class="card-top"
                   (click)="searchProspects('clicked')"
              >
                <div>
                  <h4>
                    {{ ls.data.dashboard.contactProspecting }}
                  </h4>
                  <p>
                    {{ ls.data.dashboard.exploreLinkedInProfile }}
                  </p>
                </div>
                <div>
                  <i class="fa fa-angle-right"
                     aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="card-bottom">
                <img class="login-logo"
                     src="assets/images/png/tmp.png"
                     alt="Predictiv"
                     style="width: 290px;margin-bottom: -45px;"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4">
          <div class="card bg-white card-corner">
            <div class="card-body p-1">
              <div class="card-top"
                   (click)="searchProspects('clicked')"
              >
                <div>
                  <h4>
                    {{ ls.data.dashboard.companyProspecting }}
                  </h4>
                  <p>
                    {{ ls.data.dashboard.exploreCompanyProfiles }}
                  </p>
                </div>
                <div>
                  <i class="fa fa-angle-right"
                     aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="card-bottom">
                <img class="login-logo"
                     src="assets/images/png/tmp2.png"
                     alt="Predictiv"
                     style="width: 290px;margin-bottom: -45px;"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<!-- side chrome extension -->
<ng-container *ngIf="!isChromeTab && authService.isLoggedIn && isLinkedInUrl &&
                  communicationService.linkedInScrappedData.urlType !== 'other'"
>
  <!-- header -->
  <header>
    <section class="header">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="logoDiv">
              <img class="backIco"
                   src="assets/images/svg/back.svg"
                   alt="back"
                   *ngIf="showBack" (click)="returnBack()"
              >
              <img class="logo"
                   src="assets/images/png/predictiv-logo.png"
                   alt="Predictiv"
                   (click)="goToExternalLink(environment.PRE_URL,true)"
              >
            </div>
          </div>
          <div class="col-6">
            <div class="header-actions">
              <div class="header-action"
                   *ngIf="authService.loginUserDetails?.subTier !== 'ENTPR' && activeTab !== 'settings'"
              >
                <strong class="vts-icon-color"
                        (click)="goToExternalLink(environment.PRE_URL+'/settings/pricing',true)"
                >
                  {{ ls.data.global.misc.upgrade }}
                </strong>
              </div>
              <div class="header-action"
                   (click)="toggleFill('settings',false)"
              >
                <img class="vts-svg-primary"
                     [src]="activeTab === 'settings' ? 'assets/images/svg/settings-fill.svg' : 'assets/images/svg/settings.svg'"
                     alt="settings"
                />
              </div>
              <div class="header-action">
                <i class="vts-icon-color fa fa-minus"
                   aria-hidden="true"
                   (click)="minimizeExt('minimize')"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </header>

  <!-- toast messages-->
  <div *ngIf="globalHelper.toastMessage.visibility"
       class="toast-messages"
  >
    <div class="saved_list"
         [ngClass]="{'saved_list_error': globalHelper.toastMessage.type}"
    >
      <div class="saved_list_left"
           [ngClass]="{'toastMsg': globalHelper.toastMessage.type}"
      >
        <img [src]="globalHelper.toastMessage.type ? 'assets/images/png/error.png' : 'assets/images/png/circle.png'"
             alt="warn-icon"
        >&nbsp;
        <strong>
          {{ globalHelper.toastMessage.message }}&nbsp;&nbsp;
        </strong>
      </div>
      <div class="saved_list_right">
        <i class="fa fa-times"
           aria-hidden="true"
           (click)="globalHelper.closeNotify()"
        ></i>
      </div>
    </div>
  </div>

  <section class="body">
    <div class="container p-0">
      <app-contact *ngIf="activeTab === 'contact'"
                   [isLinkedInUrl]="isLinkedInUrl"
                   [noProtocolLinkedInUrl]="noProtocolLinkedInUrl"
                   [linkedInScrappedData]="communicationService.linkedInScrappedData"
      ></app-contact>
      <app-company *ngIf="activeTab === 'company'"
                   [isLinkedInUrl]="isLinkedInUrl"
                   [noProtocolLinkedInUrl]="noProtocolLinkedInUrl"
                   [linkedInScrappedData]="communicationService.linkedInScrappedData"
      ></app-company>
      <app-employee *ngIf="activeTab === 'employee'"></app-employee>
      <app-activity *ngIf="activeTab === 'activity'"></app-activity>
      <app-settings *ngIf="activeTab === 'settings'"></app-settings>
    </div>
  </section>

  <!-- footer -->
  <footer>
    <nav>
      <div class="nav-item active"
           (click)="toggleFill('contact',false)"
           [ngClass]="{'active': activeTab === 'contact'}"
           *ngIf="!isLinkedCmpUrl"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'contact' ? 'assets/images/svg/contact-fill.svg' : 'assets/images/svg/contact.svg'"
               alt="contact"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.contact }}
          </strong>
        </div>
      </div>
      <div class="nav-item"
           (click)="toggleFill('company',false)"
           [ngClass]="{'active': activeTab === 'company'}"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'company' ?
                'assets/images/svg/company-fill.svg' : 'assets/images/svg/company.svg'"
               alt="company"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.company }}
          </strong>
        </div>
      </div>
      <div class="nav-item"
           (click)="toggleFill('employee',false)"
           [ngClass]="{'active': activeTab === 'employee'}"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'employee' ?
                'assets/images/svg/employee-fill.svg' : 'assets/images/svg/employee.svg'"
               alt="employee"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.employees }}
          </strong>
        </div>
      </div>
      <div class="nav-item"
           (click)="toggleFill('search',false)"
           [ngClass]="{'active': activeTab === 'search'}"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'search' ? 'assets/images/svg/search-fill.svg' : 'assets/images/svg/search.svg'"
               alt="search"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.search }}
          </strong>
        </div>
      </div>
      <div class="nav-item"
           (click)="toggleFill('activity',false)"
           [ngClass]="{'active': activeTab === 'activity'}"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'activity' ? 'assets/images/svg/list-fill.svg' : 'assets/images/svg/list.svg'"
               alt="activity"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.activity }}
          </strong>
        </div>
      </div>
    </nav>
  </footer>
</ng-container>
