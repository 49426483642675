<section class="non-profile">
  <div class="top">
    <p class="dash-text">
      {{ ls.data.dashboard.discoverLimitLess }}
    </p>
    <img class="top-img"
         src="assets/images/svg/top.svg"
         alt=""
    >
  </div>
  <div class="card bg-white">
    <div class="card-body">
      <div class="card-top"
           (click)="searchProspects('clicked')"
      >
        <div>
          <h4>{{ ls.data.dashboard.contactProspecting }}</h4>
          <p>{{ ls.data.dashboard.exploreLinkedInProfile }}</p>
        </div>
        <div>
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
      </div>
      <div class="card-bottom">
        <img src="assets/images/png/tmp.png" alt="">
      </div>
    </div>
  </div>
  <div class="card bg-white">
    <div class="card-body">
      <div class="card-top"
           (click)="searchProspects('clicked')"
      >
        <div>
          <h4>{{ ls.data.dashboard.companyProspecting }}</h4>
          <p>{{ ls.data.dashboard.exploreCompanyProfiles }}</p>
        </div>
        <div>
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
      </div>
      <div class="card-bottom">
        <img src="assets/images/png/tmp2.png" alt="">
      </div>
    </div>
  </div>
</section>
