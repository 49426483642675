<!--  chrome extension  -->
<app-extension-pop-up *ngIf="isChromeTab"></app-extension-pop-up>
<!-- side chrome extension -->
<ng-container *ngIf="!isChromeTab && authService.isLoggedIn">
  <!-- header -->
  <app-header [isLinkedInUrl]="isLinkedInUrl"></app-header>
  <!-- toast messages -->
  <app-toast-message *ngIf="globalHelper.toastMessage.visibility"></app-toast-message>
  <!-- body -->
  <section class="body"
           [ngClass]="{'vts-gradient-background': communicationService.linkedInScrappedData.urlType === 'other' && isLinkedInUrl}"
  >
    <app-contact *ngIf="globalHelper.activeTab === 'contact' && isLinkedInUrl &&
                    communicationService.linkedInScrappedData.urlType !== 'other'"
                 [locationUrl]="locationUrl"
                 [linkedInScrappedData]="communicationService.linkedInScrappedData"
    ></app-contact>
    <app-company *ngIf="globalHelper.activeTab === 'company'"
                 [locationUrl]="locationUrl"
                 [isLinkedInUrl]="isLinkedInUrl"
                 [linkedInScrappedData]="communicationService.linkedInScrappedData"
    ></app-company>
    <app-employee *ngIf="globalHelper.activeTab === 'employee'"></app-employee>
    <app-activity *ngIf="globalHelper.activeTab === 'activity'"></app-activity>
    <app-settings *ngIf="globalHelper.activeTab === 'settings'"></app-settings>
    <app-non-profile *ngIf="communicationService.linkedInScrappedData.urlType === 'other' &&
                        isLinkedInUrl"
    ></app-non-profile>
  </section>
  <!-- footer -->
  <app-footer *ngIf="isLinkedInUrl !== (communicationService.linkedInScrappedData.urlType === 'other')"
              [isLinkedInUrl]="isLinkedInUrl"
              [isLinkedCmpUrl]="isLinkedCmpUrl"
  ></app-footer>
</ng-container>
