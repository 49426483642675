import { IStyles } from '../themes.types';

export const ThemeLight: IStyles = {
  red: '#ff0000',
  text: '#000000',
  hover: '#e90f6b',
  green: '#37a65e',
  primary: '#e90f6b',
  shadow: '#00000029',
  tagColor: '#aeb4b7',
  secondary: '#e90f6b',
  background: '#F8F8FF',
  secondarySvg: 'initial',
  borderColor: '#70707017',
  whiteBackground: '#FFFFFF',
  gradientBackground: 'linear-gradient(to top, #e6116b80, #df87ac26)',
  primarySvg: 'invert(17%) sepia(97%) saturate(4824%) hue-rotate(326deg) brightness(92%) contrast(98%)',
};
