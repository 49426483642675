import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AppComponent } from 'src/app/app.component';
import { GlobalHelper, goToExternalLink } from '../../global.helper';
import { LanguageService } from 'src/app/dynamic/language.service';
import { AuthService } from 'src/app/services/auth.service';
import { IContactProfile, ILinkedInPayload } from '../../services/common.service.types';
import { ILinkedInScrappedData } from '../../global.types';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less'],
})
export class ContactComponent implements OnInit {

  @Input('locationUrl') locationUrl: string = '';
  @Input('linkedInScrappedData') linkedInScrappedData: ILinkedInScrappedData;
  @ViewChild('cntListSelect') cntListSelect!: NgSelectComponent;
  @ViewChild('cmpListSelect') cmpListSelect!: NgSelectComponent;

  isLoaded: boolean = false;
  isContactView: boolean = false;
  isCntListAdded: boolean = false;
  isCmpListAdded: boolean = false;
  isFaCheckFaCopyEM: boolean = false;
  isFaCheckFaCopyPO: boolean = false;
  isContactAvailable: boolean = false;
  isFaCheckFaCopyShowEM: boolean = false;
  isFaCheckFaCopyShowPD: boolean = false;
  isFaCheckFaCopyShowPO: boolean = false;
  linkedinContactPlaceholder: string = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  constructor(
    public commonService: CommonService,
    public globalHelper: GlobalHelper,
    public appComponent: AppComponent,
    public authService: AuthService,
    public ls: LanguageService,
  ) {
  }

  ngOnInit(): void {
    if (!this.authService.loginUserDetails) {
      return;
    }
    if (this.authService.isLoggedIn && !this.authService.planExpired) {
      this.getLinkedInProfileData(false, 'onLoad')
        .then();
      this.initMethods();
    }
  }

  initMethods(): void {
    this.globalHelper.getDefaultCntList();
    this.globalHelper.getDefaultCmpList();
    this.globalHelper.getDefaultList();
  }

  async getLinkedInProfileData(isView: boolean, type: string): Promise<void> {
    this.isLoaded = true;

    if (!this.locationUrl.includes('linkedin.com/in/')) {
      this.isLoaded = false;
      return;
    }

    const { contact } = this.linkedInScrappedData;
    const request: ILinkedInPayload = {
      linkedIn: this.locationUrl,
      isView,
      fullName: contact.contactName,
      homeLocation: contact.experience[0].workLocation,
      companyName: contact.experience[0].companyName,
      jobTitle: contact.experience[0].jobTitle,
      workLocation: contact.experience[0].workLocation,
      workDuration: contact.experience[0].workDuration,
      experience: contact.experience,
      cmpLiUrl: contact.experience[0].companyLiUrl,
      cmpLogoUrl: contact.experience[0].companyLogoUrl,
      contactLogo: contact.contactImageUrl,
      contactLiUrl: this.linkedInScrappedData.tabUrl,
      linkedInHTML: this.linkedInScrappedData.linkedinHTML,
      isCmpUrl: false,
      isContact: true,
      isLinkedinUrl: true,
      chromeExtCredits: true,
    };

    try {
      const profileData = await this.commonService.getContactProfile(request);
      if (profileData) {
        this.isContactAvailable = true;
        await this.updateLinkedInData(profileData, type, isView);
      }
    } catch (error: any) {
      this.globalHelper.toastMessages(this.ls.takeErrorMsg(error?.error?.langCode), 'error');
    } finally {
      this.isLoaded = false;
    }
  }

  async updateLinkedInData(profileData: IContactProfile, type: string, isView: boolean) {
    const linkedInExperience = this.linkedInScrappedData?.contact?.experience[0];
    const defaultCompanyName = linkedInExperience?.companyName || profileData.companyName;
    const defaultJobTitle = linkedInExperience?.jobTitle || profileData.jobTitle;
    const companyLogoUrl: any = await this.globalHelper.getCompanyLogos(profileData.domainId);
    this.globalHelper.pDetails = {
      ...this.globalHelper.pDetails,
      fullName: `${profileData.firstName} ${profileData.lastName}`,
      contactLiUrl: profileData.contactLiUrl || '',
      companyId: profileData.companyId || '',
      work_email: profileData.emailAddress || 'Not Available',
      phone_office: profileData.phoneOffice || 'Not Available',
      phone_direct: profileData.phoneDirect || 'Not Available',
      id: profileData.id || '',
      contactId: profileData.contactId || '',
      imageUrl: await this.globalHelper.getContactLogoUrl(profileData.contactLogoId),
      avatarStyle: this.globalHelper.getRandomAvatarStyle(),
      cmpLogo: companyLogoUrl,
      parentDept: profileData.parentDept || 'Not Available',
      emp_range: profileData.empRange || 'Not Available',
      rev_range: profileData.revRange || 'Not Available',
      hq_location: profileData.hqLocation || 'Not Available',
      company_li_url: profileData.companyLiUrl || '',
      company_facebook_url: profileData.companyFacebookUrl || '',
      isSubExpire: profileData.isSubExpire,
      website: profileData.website || 'Not Available',
      specialty: Array.from(new Set(profileData.specialty)) || 'Not Available',
      founded: profileData.founded || 'Not Available',
      company_description: profileData.companyDescription || '',
      company_name: defaultCompanyName,
      job_title: defaultJobTitle,
    };

    this.isContactView = isView || profileData.alreadyViewCnt || false;
    this.updateCopyFlags(profileData);

    if (type === 'onLoad') {
      this.updateContactAndCompanyLists();
    }
  }

  updateCopyFlags(profileData: IContactProfile): void {
    this.isFaCheckFaCopyShowEM = !!profileData.emailAddress;
    this.isFaCheckFaCopyShowPD = !!profileData.phoneDirect;
    this.isFaCheckFaCopyShowPO = !!profileData.phoneOffice;
    this.isFaCheckFaCopyEM = !(profileData.emailAddress && profileData.emailAddress.includes('*'));
    this.isFaCheckFaCopyPO = !(profileData.phoneOffice && profileData.phoneOffice.includes('*'));
  }

  updateContactAndCompanyLists(): void {
    const {
      contactId,
      companyId,
    } = this.globalHelper.pDetails;
    if (contactId) {
      this.globalHelper.getCntList(contactId);
      this.globalHelper.getListSave('contact', contactId);
    }
    if (companyId) {
      this.globalHelper.getCmpList(companyId);
      this.globalHelper.getListSave('company', companyId);
    }
  }

  showDetails(): void {
    this.isContactInfoUnavailable() ? this.isContactView = true : this.getLinkedInProfileData(true, 'office_email')
      .then();
  }

  isContactInfoUnavailable(): boolean {
    const {
      work_email,
      phone_office,
      phone_direct,
    } = this.globalHelper.pDetails;
    return work_email === 'Not Available' && phone_office === 'Not Available' && phone_direct === 'Not Available';
  }

  onListCancel(type: 'contact' | 'company'): void {
    const listSelect: NgSelectComponent = type === 'contact' ? this.cntListSelect : this.cmpListSelect;
    if (listSelect) {
      listSelect.clearModel();
    }
    this.isCntListAdded = type === 'contact' ? false : this.isCntListAdded;
    this.isCmpListAdded = type === 'company' ? false : this.isCmpListAdded;
  }

  saveContactList(saveCntList: string[]): void {
    this.globalHelper.saveLists('contact', saveCntList, 'contactId', this.cntListSelect);
    this.onListCancel('contact');
  }

  saveCompanyList(saveCmpList: string[]): void {
    this.globalHelper.saveLists('company', saveCmpList, 'companyId', this.cmpListSelect);
    this.onListCancel('company');
  }

  addToCmpList(): void {
    this.globalHelper.getCmpList(this.globalHelper.pDetails.companyId);
    const defaultCmpList = this.globalHelper.defaultList.defaultSelectedCmpList;
    if (defaultCmpList !== undefined) {
      if (this.globalHelper.pluginSettings.askWhereToSave) {
        this.isCmpListAdded = !this.isCmpListAdded;
        this.globalHelper.selectedListType.selectedCmpList = [defaultCmpList];
      } else {
        this.isCmpListAdded = false;
        this.saveCompanyList([defaultCmpList]);
      }
    } else {
      this.isCmpListAdded = !this.isCmpListAdded;
    }
    this.cmpListSelect?.clearModel();
  }

  addToCntList(): void {
    this.globalHelper.getCntList(this.globalHelper.pDetails.contactId);
    const defaultCntList = this.globalHelper.defaultList.defaultSelectedCntList;
    if (defaultCntList !== undefined) {
      if (this.globalHelper.pluginSettings.askWhereToSave) {
        this.isCntListAdded = !this.isCntListAdded;
        this.globalHelper.selectedListType.selectedCntList = [defaultCntList];
      } else {
        this.isCntListAdded = false;
        this.saveContactList([defaultCntList]);
      }
    } else {
      this.isCntListAdded = !this.isCntListAdded;
    }
    this.cntListSelect?.clearModel();
  }

  protected readonly goToExternalLink = goToExternalLink;
}
