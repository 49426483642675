import { Component, OnInit } from '@angular/core';
import { GlobalHelper } from '../../global.helper';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.less'],
})
export class ToastMessageComponent implements OnInit {

  constructor(public globalHelper: GlobalHelper) {
  }

  ngOnInit(): void {
  }

}
