<footer>
  <ng-container *ngFor="let tab of tabs">
    <div *ngIf="tab !== 'contact' || (!linkedInScrappedData.tabUrl.includes('linkedin.com/company/') &&
            linkedInScrappedData.tabUrl.includes('linkedin.com'))"
         class="nav-item"
         (click)="globalHelper.toggleFill(tab, false)"
         [ngClass]="{'active': globalHelper.activeTab === tab}"
    >
      <img class="vts-svg-primary"
           [src]="'assets/images/svg/' + tab + (globalHelper.activeTab === tab ? '-fill' : '') + '.svg'"
           alt=""
      >
      <strong>{{ tab | titlecase }}</strong>
    </div>
  </ng-container>
</footer>
