<div class="main-body"
     *ngIf="!settingsLoader"
>
  <!--  user profile -->
  <div class="custom-card">
    <div class="profile-card">
      <div class="profile-desc">
        <div class="profile-logo">
          <div class="initials"
               [ngStyle]="avatarStyle"
          >
            {{ globalHelper.getInitials(globalHelper.userProfile.firstName + ' ' + globalHelper.userProfile.lastName) }}
          </div>
        </div>
        <div class="profile-details">
          <h6 class="overflow-text user-name">
            {{ globalHelper.userProfile.firstName + ' ' + globalHelper.userProfile.lastName }}
          </h6>
          <div class="plan">
            <div class="your-plan">
              {{ globalHelper.subscription.tierName | uppercase }}
            </div>
            <div class="vts-icon-color upgrade"
                 (click)="redirectToApp('upgrade')"
                 *ngIf="globalHelper.subscription.tierName!=='Enterprise'"
            >
              {{ ls.data.global.misc.upgrade }}
            </div>
          </div>
          <div class="edit"
               [tooltip]="ls.data.global.toolTip.editProfile"
          >
            <span class="vts-icon-color"
                  (click)="redirectToApp('edit-profile')"
            >
              {{ ls.data.global.buttons.edit }}
            </span>
          </div>
          <div class="credits d-none"
               *ngIf="globalHelper.chExtCredits"
          >
            <div class="vts-icon-color credit-label">
              {{ ls.data.settings.credits }}
            </div>
            <div class="custom-progress-bar">
              <div class="vts-icon-color stages">
                {{ globalHelper.chExtCredits.usedChromeExtCredits }} / {{ globalHelper.chExtCredits.chExtCredits }}
              </div>
              <div class="progress-bar-container">
                <div class="progress-bar"
                     [style.width.%]="globalHelper.chExtCredits.progress"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- predictiv platform -->
  <div class="custom-card">
    <div class="card-top card-header-border">
      <h6 class="vts-tag-color">
        {{ ls.data.settings.predictivPlatform }}
      </h6>
    </div>
    <div class="card-bottom">
      <div class="redirect-actions">
        <div *ngFor="let action of redirectActions; let i = index"
             class="redirect-action">
          <div class="redirect-action-left">
            <div class="ico {{'ico'+i}}">
              <img class="vts-svg-primary"
                   [ngClass]="'ico'+i"
                   [src]="action.imgUrl"
                   alt=""
              >
            </div>
            <div class="redirect-action-title">
              <h6 class="pointer-cursor"
                  (click)="redirectToApp(action.url)"
              >
                {{ action.title }}
              </h6>
            </div>
          </div>
          <div class="redirect-action-right">
            <div class="ico redirect"
                 (click)="redirectToApp(action.url)"
            >
              <img class="vts-svg-primary"
                   [src]="'assets/images/svg/redirect.svg'"
                   alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- extension preferences -->
  <div class="custom-card">
    <div class="card-top card-header-border">
      <h6 class="vts-tag-color">
        {{ ls.data.settings.extensionPreferences }}
      </h6>
    </div>
    <div class="card-bottom">
      <div class="redirect-actions">
        <div class="redirect-action">
          <div class="redirect-action-left">
            <div class="ico preferences">
              <img class="vts-svg-primary"
                   [src]="'assets/images/svg/plugins.svg'"
                   alt=""
              >
            </div>
            <div class="redirect-action-title">
              <h6>
                {{ ls.data.settings.pluginPosition }}
              </h6>
            </div>
          </div>
          <div class="redirect-action-right">
            <div class="plugin-position">
              <img class="vts-svg-primary"
                   [src]="globalHelper.pluginSettings.position !== 'left' ? 'assets/images/svg/left.svg' :
                        'assets/images/svg/leftFil.svg' "
                   (click)="changePosition('left')"
                   alt=""
              >
              <img class="vts-svg-primary"
                   [src]="globalHelper.pluginSettings.position !== 'right' ? 'assets/images/svg/right.svg':
                        'assets/images/svg/rightFill.svg'"
                   (click)="changePosition('right')"
                   alt=""
              >
            </div>
          </div>
        </div>
        <div class="redirect-action">
          <div class="redirect-action-left">
            <div class="ico preferences">
              <img class="vts-svg-primary"
                   [src]="'assets/images/svg/autoOpen.svg'"
                   alt=""
              >
            </div>
            <div class="redirect-action-title">
              <h6>
                {{ ls.data.settings.autoOpen }}
              </h6>
            </div>
          </div>
          <div class="redirect-action-right">
            <div class="toggle">
              <label class="switch">
                <input type="checkbox"
                       [checked]="globalHelper.pluginSettings.open"
                       (click)="autoOpen($event)"
                >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="redirect-action">
          <div class="redirect-action-left">
            <div class="ico preferences">
              <img class="vts-svg-primary"
                   [src]="'assets/images/svg/everywhere.svg'"
                   alt=""
              >
            </div>
            <div class="redirect-action-title">
              <h6>
                {{ ls.data.settings.predictivEveryWhere }}
              </h6>
            </div>
          </div>
          <div class="redirect-action-right">
            <div class="toggle">
              <label class="switch">
                <input type="checkbox"
                       [checked]="globalHelper.pluginSettings.everywhere"
                       (click)="predictivEveryWhere($event)"
                >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- default list -->
  <div class="custom-card">
    <div class="card-top card-header-border">
      <h6 class="vts-tag-color">
        {{ ls.data.settings.defaultList }}
      </h6>
    </div>
    <div class="card-bottom">
      <div class="redirect-actions">
        <!-- contact -->
        <div class="redirect-action ng-select-wrapper">
          <div class="redirect-action-left">
            <div class="redirect-action-title">
              <h6>{{ ls.data.activities.contact }}</h6>
            </div>
          </div>
          <div class="redirect-action-right ">
            <div class="action action-list">
              <div class="vts-border-theme addList">
                <div class="form-group">
                  <ng-select [items]="globalHelper.defaultListData.cntListData"
                             bindLabel="label"
                             bindValue="label"
                             [addTag]="globalHelper.addList"
                             [addTagText]="ls.data.company.createNewList"
                             [multiple]="false" [placeholder]="ls.data.settings.defaultList"
                             [(ngModel)]="globalHelper.defaultList.defaultSelectedCntList"
                             (change)="onListChange($event,'contact')"
                             (clear)="globalHelper.getDefaultCntList()"
                  ></ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- company -->
        <div class="redirect-action ng-select-wrapper">
          <div class="redirect-action-left">
            <div class="redirect-action-title">
              <h6>
                {{ ls.data.activities.company }}
              </h6>
            </div>
          </div>
          <div class="redirect-action-right">
            <div class="action action-list">
              <div class="vts-border-theme addList">
                <div class="form-group">
                  <ng-select [items]="globalHelper.defaultListData.cmpListData"
                             bindLabel="label"
                             bindValue="label"
                             [addTag]="globalHelper.addList"
                             [addTagText]="ls.data.company.createNewList"
                             [multiple]="false" [placeholder]="ls.data.settings.defaultList"
                             [(ngModel)]="globalHelper.defaultList.defaultSelectedCmpList"
                             (change)="onListChange($event,'company')"
                             (clear)="globalHelper.getDefaultCmpList()"
                  ></ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="redirect-action where-to-save">
          <div class="redirect-action-left">
            <div class="redirect-action-title">
              <h6>
                {{ ls.data.settings.askWhereToSaveList }}
              </h6>
            </div>
          </div>
          <div class="redirect-action-right">
            <div class="toggle">
              <label class="switch">
                <input type="checkbox"
                       [checked]="globalHelper.pluginSettings.askWhereToSave"
                       (click)="whereToSave($event)"
                >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- themes -->
  <div class="custom-card">
    <div class="card-top card-header-border">
      <h6 class="vts-tag-color">
        {{ ls.data.settings.themes }}
      </h6>
    </div>
    <div class="card-bottom">
      <div class="redirect-actions">
        <div class="redirect-action">
          <div class="redirect-action-left">
            <div class="themes">
              <div class="radio blue"
                   (click)="setTheme('blue-theme')"
                   [ngClass]="{'selected-theme':globalHelper.pluginSettings.theme === 'blue-theme'}"
              >
              </div>
              <div class="radio light"
                   (click)="setTheme('light-theme')"
                   [ngClass]="{'selected-theme':globalHelper.pluginSettings.theme === 'light-theme'}"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- skeleton -->
<ng-container *ngIf="settingsLoader">
  <div class="skeleton">
    <div class="custom-skeleton">
      <div class="p-d-flex p-mb-3">
        <div class="circle p-mr-2 skeleton-effect"
             size="4rem"
        ></div>
        <div class="stripes">
          <div class="p-mb-2 skeleton-effect"
               *ngFor="let _ of [1,2]"
          ></div>
        </div>
      </div>
      <div class="stripes fullStripes">
        <div class="p-mb-2 skeleton-effect"
             *ngFor="let _ of [1,2,3,4,5,6,7]"
        ></div>
      </div>
    </div>
    <div class="custom-skeleton">
      <div class="stripes fullStripes">
        <div class="p-mb-2 skeleton-effect"
             *ngFor="let _ of [1,2,3,4,5,6,7]"
        ></div>
      </div>
    </div>
  </div>
</ng-container>
