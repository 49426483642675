<header>
  <div class="logo-wrapper">
    <img *ngIf="globalHelper.showBack"
         class="back-icon"
         src="assets/images/svg/back.svg"
         alt=""
         (click)="returnBack()"
    >
    <img class="logo"
         src="assets/images/png/predictiv-logo.png"
         alt=""
         (click)="goToExternalLink(environment.PRE_URL,true)"
    >
  </div>
  <div class="header-actions">
    <div class="header-action"
         *ngIf="globalHelper.subscription.tierName !== 'Enterprise' &&
             globalHelper.activeTab !== 'settings' &&
             (linkedInScrappedData.tabUrl.includes('linkedin.com') !==
             (linkedInScrappedData.urlType === 'other'))"
    >
      <strong class="vts-icon-color"
              (click)="goToExternalLink(environment.PRE_URL+'/settings/pricing',true)"
      >
        {{ ls.data.global.misc.upgrade }}
      </strong>
    </div>
    <div class="header-action"
         *ngIf="linkedInScrappedData.tabUrl.includes('linkedin.com') !==
            (linkedInScrappedData.urlType === 'other')"
         (click)="globalHelper.toggleFill('settings',false)"
    >
      <img class="vts-svg-primary"
           [src]="globalHelper.activeTab === 'settings' ? 'assets/images/svg/settings-fill.svg' : 'assets/images/svg/settings.svg'"
           alt=""
      />
    </div>
    <div class="header-action">
      <i class="vts-icon-color fa fa-minus"
         aria-hidden="true"
         (click)="minimizeExt('minimize')"
      ></i>
    </div>
  </div>
</header>
