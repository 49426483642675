<!--  chrome extension  -->
<app-extension-pop-up *ngIf="isChromeTab"></app-extension-pop-up>
<!-- side chrome extension -->
<ng-container *ngIf="!isChromeTab && authService.isLoggedIn">
  <!-- header -->
  <app-header></app-header>
  <!-- toast messages -->
  <app-toast-message *ngIf="globalHelper.toastMessage.visibility"></app-toast-message>
  <!-- body -->
  <section class="body"
           [ngClass]="{'vts-gradient-background': linkedInScrappedData.urlType === 'other' &&
                linkedInScrappedData.tabUrl.includes('linkedin.com')
           }"
  >
    <app-non-profile *ngIf="linkedInScrappedData.urlType === 'other' &&
                         linkedInScrappedData.tabUrl.includes('linkedin.com')"
    ></app-non-profile>
    <app-contact *ngIf="globalHelper.activeTab === 'contact' &&
                    linkedInScrappedData.urlType !== 'other' &&
                    linkedInScrappedData.tabUrl.includes('linkedin.com')"
    ></app-contact>
    <app-company *ngIf="globalHelper.activeTab === 'company'"></app-company>
    <app-employee *ngIf="globalHelper.activeTab === 'employee'"></app-employee>
    <app-activity *ngIf="globalHelper.activeTab === 'activity'"></app-activity>
    <app-settings *ngIf="globalHelper.activeTab === 'settings'"></app-settings>
  </section>
  <!-- footer -->
  <app-footer *ngIf="linkedInScrappedData.tabUrl.includes('linkedin.com') !==
                 (linkedInScrappedData.urlType === 'other')"
  ></app-footer>
</ng-container>
