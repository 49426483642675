import { Injectable } from '@angular/core';
import { CommonService } from './services/common.service';
import { LanguageService } from './dynamic/language.service';
import { ThemesService } from './themes/themes.service';
import { ESupportedThemes } from './themes/themes.types';
import { environment } from 'src/environments/environment';
import {
  emptyICompanyListData,
  emptyIContactListData,
  emptyIDefaultList,
  emptyIDefaultListData,
  emptyIEmployeeData,
  emptyIListData,
  emptyIPluginSettings,
  emptyIProfileData,
  emptyISelectedListType,
  emptyIsExpanded,
  emptyIsSaved,
  emptyIToastMessage,
  IChromeExtCredits,
  ICompanyListData,
  IContactListData,
  IDataItem,
  IDefaultList,
  IDefaultListData,
  IEmployeeData,
  IIsExpanded,
  IIsSaved,
  IListData,
  IPluginSettings,
  IProfileData,
  ISelectedListType,
  ISubscription,
  IToastMessage,
  IUserProfile,
} from './global.types';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CommunicationService } from './services/communication.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalHelper {

  isSaved: IIsSaved = COPY(emptyIsSaved);
  listData: IListData = COPY(emptyIListData);
  pDetails: IProfileData = COPY(emptyIProfileData);
  isExpanded: IIsExpanded = COPY(emptyIsExpanded);
  defaultList: IDefaultList = COPY(emptyIDefaultList);
  employeeData: IEmployeeData = COPY(emptyIEmployeeData);
  toastMessage: IToastMessage = COPY(emptyIToastMessage);
  cntListData: IContactListData = COPY(emptyIContactListData);
  cmpListData: ICompanyListData = COPY(emptyICompanyListData);
  pluginSettings: IPluginSettings = COPY(emptyIPluginSettings);
  defaultListData: IDefaultListData = COPY(emptyIDefaultListData);
  selectedListType: ISelectedListType = COPY(emptyISelectedListType);

  chExtCredits: IChromeExtCredits;
  userProfile: IUserProfile;
  subscription: ISubscription;

  hoveredItem: HTMLDivElement | null = null;

  preDefAvatarColors = [
    {
      background: '#E8E8E8',
      text: '#8C8A94',
    },
    {
      background: '#DBDDFF',
      text: '#4F59B7',
    },
    {
      background: '#FFDEEC',
      text: '#E90F6B',
    },
    {
      background: '#C2FBD6',
      text: '#00D38E',
    },
    {
      background: '#D1E4F9',
      text: '#101571',
    },
    {
      background: '#E0FFFFFF',
      text: '#008B8BFF',
    },
    {
      background: '#ffeee1',
      text: '#ffb138',
    },
    {
      background: '#e3f6f2',
      text: '#01a886',
    },
  ];

  addList = (term: string) => ({
    label: term,
    value: term,
  });

  constructor(
    private commonService: CommonService,
    private themesService: ThemesService,
    private ls: LanguageService,
    private communicationService: CommunicationService,
  ) {
  }

  goToDetails(id: string, type: 'company' | 'contact') {
    if (!id) {
      return;
    }
    const urlSuffix = type === 'company' ? 'company' : 'contact';
    const fullUrl = `search/${urlSuffix}/${id}`;
    if (this.pDetails.isSubExpire === '') {
      goToExternalLink(`${environment.PRE_URL}/${fullUrl}`, true);
    } else {
      this.toastMessages(this.ls.data.errors.SIE, 'error');
    }
  }

  getDefaultList() {
    this.commonService.getSetting()
      .then((result: any) => {
        this.defaultList.defaultSelectedCntList = result?.defaultListContactName ? result?.defaultListContactName : undefined;
        this.defaultList.defaultSelectedCmpList = result?.defaultListCompanyName ? result?.defaultListCompanyName : undefined;
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  getCompanyLogos(cmpId: any) {
    return new Promise((resolve, reject) => {
      this.commonService.getCompanyLogos({ domainIds: cmpId })
        .then((success: any) => {
          if (success.length && success[0].logo) {
            resolve(success);
          } else {
            resolve('');
          }
        })
        .catch((_error: any) => {
          reject(_error);
        });
    });
  }

  toastMessages(message: any, type: any) {
    this.toastMessage.visibility = true;
    this.toastMessage.message = message;
    this.toastMessage.type = type === 'error';

    setTimeout(() => {
      this.toastMessage.visibility = false;
    }, 3000);
  }

  closeNotify() {
    this.toastMessage.visibility = false;
  }

  toggleDescription() {
    this.isExpanded.open = !this.isExpanded.open;
  }

  getSettings(loadType?: string) {
    this.commonService.getSetting()
      .then((result: any) => {
        this.pluginSettings.open = loadType === 'login' ? true : (result.isAutoOpen ? result.isAutoOpen : false);
        this.pluginSettings.everywhere = result.isPredictivEveryWhere ? result.isPredictivEveryWhere : false;
        this.pluginSettings.askWhereToSave = result.askWhereToSave ? result.askWhereToSave : false;
        this.pluginSettings.position = result.position === 'left' ? 'left' : 'right';
        this.pluginSettings.theme = result.selectedTheme ? result.selectedTheme : 'blue-theme';
        this.themesService.defineThemes(this.pluginSettings.theme as ESupportedThemes);
        this.communicationService.postMessageToParent(this.pluginSettings);
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  getDefaultCntList() {
    this.commonService.getDefaultCntList()
      .then((result: any) => {
        this.defaultListData.cntListData = result ? result : [];
      })
      .catch((_error: any) => {
          console.error('_error', _error);
        },
      );
  }

  getDefaultCmpList() {
    this.commonService.getDefaultCmpList()
      .then((result: any) => {
        this.defaultListData.cmpListData = result ? result : [];
      })
      .catch((_error: any) => {
          console.error('_error', _error);
        },
      );
  }

  getChExtCredits() {
    this.commonService.getChExtCredits()
      .then((result: any) => {
        this.chExtCredits = result ? result : [];
        this.chExtCredits.progress = (this.chExtCredits?.usedChromeExtCredits / this.chExtCredits?.chExtCredits) * 100;
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  getCntList(contactId: string) {
    let requestData = {
      'limit': 100,
      'moduleType': ['mylist'],
      'flag': 'chExt',
      'contactId': contactId,
      'sort': {
        'attribute': 'createdDate',
        'orderType': -1,
      },
      'subType': 'contact',
    };
    this.commonService.getList(requestData)
      .then((cntListData: any) => {
        this.cntListData.cntListDataAvl = cntListData?.resObj?.compcontNotAvailable?.length ? cntListData?.resObj?.compcontNotAvailable : [];
        this.cntListData.cntListDataNotAvl = cntListData?.resObj?.compcontAvailable?.length ? cntListData?.resObj?.compcontAvailable : [];
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  getCmpList(companyId: string) {
    let requestData = {
      'limit': 100,
      'moduleType': ['mylist'],
      'flag': 'chExt',
      'companyId': companyId,
      'sort': {
        'attribute': 'createdDate',
        'orderType': -1,
      },
      'subType': 'account',
    };
    this.commonService.getList(requestData)
      .then((cmpListData: any) => {
        this.cmpListData.cmpListDataAvl = cmpListData?.resObj?.compcontNotAvailable?.length ? cmpListData?.resObj?.compcontNotAvailable : [];
        this.cmpListData.cmpListDataNotAvl = cmpListData?.resObj?.compcontAvailable?.length ? cmpListData?.resObj?.compcontAvailable : [];
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  saveLists(type: 'contact' | 'company', saveList: string[], idField: 'contactId' | 'companyId', selectComponent: NgSelectComponent): void {
    if (saveList.length) {
      const body = {
        indexIds: [(this.pDetails as any)[idField]],  // Ensure pDetails has this field
        moduleType: 'mylist',
        type: type,
        listName: saveList,
      };

      const saveFunc = type === 'contact' ? this.commonService.saveCntList.bind(this.commonService) : this.commonService.saveCmpList.bind(this.commonService);  // Bind the context

      saveFunc(body)
        .then(() => {
          this.toastMessages(this.ls.data.company.savedToList, 'success');
        })
        .catch(() => {
          if (type === 'contact') {
            this.toastMessages(this.ls.data.contact.contactAlreadyExist, 'error');
          } else {
            this.toastMessages(this.ls.data.company.companyAlreadyExist, 'error');
          }
        })
        .finally(() => {
          this.getListSave(type, this.pDetails[idField]);
          selectComponent.clearModel();
        });
    }
  }

  getListSave(type: 'contact' | 'company', id: string): void {
    let saveMethod: (id: string) => Promise<any>;
    let isSavedKey: 'isCntSaved' | 'isCmpSaved';

    if (type === 'contact') {
      saveMethod = this.commonService.getContactListSave.bind(this.commonService);
      isSavedKey = 'isCntSaved';
    } else {
      saveMethod = this.commonService.getCompanyListSave.bind(this.commonService);
      isSavedKey = 'isCmpSaved';
    }

    saveMethod(id)
      .then((isSaved: any) => {
        this.isSaved[isSavedKey] = isSaved;
      })
      .catch((error: any) => {
        console.error('error:', error);
      });
  }

  onListChange(type: 'contact' | 'company') {
    if (type === 'contact') {
      if (this.selectedListType.selectedCntList.some((item: any) => this.cntListData.cntListDataNotAvl.some((obj: any) => obj.value === item))) {
        const elementToRemove = this.selectedListType.selectedCntList.find((item: any) =>
          this.cntListData.cntListDataNotAvl.some((obj: any) => obj.value === item),
        );
        if (elementToRemove) {
          const index = this.selectedListType.selectedCntList.indexOf(elementToRemove);
          if (index !== -1) {
            this.selectedListType.selectedCntList.splice(index, 1);
          }
        }
        this.toastMessages(this.ls.data.company.fileNameAlreadyExist, 'success');
      }
      this.selectedListType.selectedCntList = Array.from(new Set(this.selectedListType.selectedCntList));
    } else {
      if (this.selectedListType.selectedCmpList.some((item: any) => this.cmpListData.cmpListDataNotAvl.some((obj: any) => obj.value === item))) {
        const elementToRemove = this.selectedListType.selectedCmpList.find((item: any) =>
          this.cmpListData.cmpListDataNotAvl.some((obj: any) => obj.value === item),
        );
        if (elementToRemove) {
          const index = this.selectedListType.selectedCmpList.indexOf(elementToRemove);
          if (index !== -1) {
            this.selectedListType.selectedCmpList.splice(index, 1);
          }
        }
        this.toastMessages(this.ls.data.company.fileNameAlreadyExist, 'success');
      }
      this.getCmpList(this.pDetails.companyId);
      this.selectedListType.selectedCmpList = Array.from(new Set(this.selectedListType.selectedCmpList));
    }
  }

  async getEmployeeList(from: number, size: number, search?: any, filterDept?: any, filterSeniority?: any, filterJobLevel?: boolean) {
    try {
      const cntLogoUrls: string[] = [];
      this.employeeData.cndDataLoader = true;
      const requestData = {
        companyId: this.pDetails.companyId,
        contactId: this.pDetails.contactId,
        from: from,
        size: size,
        isView: false,
        search: search,
        filterDept: filterDept,
        filterSeniority: filterSeniority,
        filterJobLevel: filterJobLevel,
        chExtCredits: true,
      };
      const contactData: any = await this.commonService.getContactList(requestData)
        .then();
      if (!contactData) {
        this.employeeData.cntData = [];
        return;
      }
      contactData.forEach((contact: any) => {
        const avatarColorPair = this.preDefAvatarColors[Math.floor(Math.random() * this.preDefAvatarColors.length)];
        if (contact.contactLogoId) {
          if (!cntLogoUrls.includes(contact.contactLogoId)) {
            cntLogoUrls.push(contact.contactLogoId);
          }
        }
        contact.initials = makeAbbreviation(contact.fullName);
        contact.avatarStyle = {
          'background-color': avatarColorPair.background,
          'color': avatarColorPair.text,
        };
      });
      if (cntLogoUrls.length) {
        this.commonService.getContactLogos({ contactLogoIds: cntLogoUrls })
          .then((logos: any[] | undefined) => {
            contactData.forEach((contact: any) => {
              const item: any = logos?.find((logo: any) => contact.contactLogoId === logo.contactLogoId);
              if (item?.contactLogo) {
                contact.imageUrl = environment.CPM_LOGO_URL + item.contactLogo;
              }
            });
          });
      }
      this.employeeData.cntData = contactData;
    } catch (error) {
      console.error('Error fetching employee data:', error);
    } finally {
      this.employeeData.cndDataLoader = false;
    }
  }

  async getContactLogoUrl(contactLogoId: string): Promise<string> {
    try {
      const success: any[] = await this.commonService.getContactLogos({ contactLogoIds: [contactLogoId] })
        .then();

      const cntLogo = success && success.length > 0 ? success[0].contactLogo : null;
      if (cntLogo) {
        return `${environment.CPM_LOGO_URL}${cntLogo}`;
      } else {
        return '';
      }
    } catch (error) {
      return '';
    }
  }

  async getCompanyLogoUrl(companyLogoId: string): Promise<string> {
    try {
      const success: any[] = await this.commonService.getCompanyLogos({ domainIds: [companyLogoId] })
        .then();

      const cntLogo = success && success.length > 0 ? success[0].logo : null;
      if (cntLogo) {
        return `${environment.CPM_LOGO_URL}${cntLogo}`;
      } else {
        return '';
      }
    } catch (error) {
      return '';
    }
  }

  async getListData(type: any, limit?: number, offset?: number) {
    const requestType: string[] = type === 'Recent' ? ['company', 'contact'] : [type.toLowerCase()];
    const request: any = {
      type: requestType,
      isView: false,
    };
    try {
      const listData: any = await this.commonService.chromeExtRecentSearch(request, limit, offset);
      if (!listData.length) {
        this.listData.enabledScroll = false;
        return;
      }
      const processedData: IDataItem[] = await this.processListData(listData);

      // Helper function to get unique items
      const uniqueById = (items: IDataItem[]): IDataItem[] => {
        const seen: Set<string> = new Set<string>();
        return items.filter((item: IDataItem) => {
          const id: string = item.type === 'company' ? item.companyid : item.id;
          if (seen.has(id)) {
            return false;
          }
          seen.add(id);
          return true;
        });
      };

      this.listData.data = uniqueById([...this.listData.data, ...processedData]);
      this.listData.cntData = await this.processFilteredData(this.listData.data, 'contact');
      this.listData.cmpData = await this.processFilteredData(this.listData.data, 'company');
    } catch (error) {
      console.error('error', error);
    } finally {
      this.listData.cndDataLoader = false;
    }
  }

  private async processListData(listData: any[]): Promise<any[]> {
    return Promise.all(listData.map(async(employee: any) => {
      try {
        const imageUrl: string = await this.getImageUrl(employee);
        return {
          ...employee,
          imageUrl,
          avatarStyle: imageUrl ? {} : this.getRandomAvatarStyle(),
        };
      } catch (error) {
        console.error('error', error);
        return {
          ...employee,
          imageUrl: '',
          avatarStyle: {},
        };
      }
    }));
  }

  private async processFilteredData(listData: any[], type: string): Promise<any[]> {
    return listData.filter((item: { type: string }) => item.type === type);
  }

  private async getImageUrl(employee: any): Promise<string> {
    if (employee.contactLogoId) {
      return this.getContactLogoUrl(employee.contactLogoId);
    } else if (employee.companyLogoId) {
      return this.getCompanyLogoUrl(employee.companyLogoId);
    }
    return '';
  }

  getRandomAvatarStyle() {
    const avatarColorPair = this.preDefAvatarColors[Math.floor(Math.random() * this.preDefAvatarColors.length)];
    return {
      'background-color': avatarColorPair.background,
      'color': avatarColorPair.text,
    };
  }

  getProfileDetails() {
    this.commonService.getProfileDetails()
      .then((result: any) => {
        this.userProfile = result;
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  getSubscriptionInfo() {
    this.commonService.getSubscriptionInfo()
      .then((result: any) => {
        this.subscription = result;
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  getInitials(fullName: string) {
    if (!fullName) {
      return '';
    }
    return fullName.split(' ')
      .map((name: string) => name.charAt(0)
        .toUpperCase())
      .join('');
  }

  showHover(item: HTMLDivElement) {
    this.hoveredItem = item;
  }

  hideHover(item: HTMLDivElement) {
    if (this.hoveredItem === item) {
      this.hoveredItem = null;
    }
  }

  getActiveTabLinkedinUrl(tabUrl: string) {
    return new Promise((resolve, reject) => {
      try {
        const url = new URL(tabUrl);
        const searchParams = new URLSearchParams(url.search);
        let finalLinkedInUrl = '';

        if (searchParams.has('sessionRedirect')) {
          finalLinkedInUrl = searchParams.get('sessionRedirect')!;
        } else if (searchParams.has('session_redirect')) {
          finalLinkedInUrl = searchParams.get('session_redirect')!;
        } else {
          const path = url.pathname;
          const isProfilePath = path.match(/in\/([^\/]+)/);
          const isCompanyPath = path.match(/company\/([^\/]+)/);

          if (isProfilePath) {
            finalLinkedInUrl = `linkedin.com/in/${isProfilePath[1]}`;
          } else if (isCompanyPath) {
            finalLinkedInUrl = `linkedin.com/company/${isCompanyPath[1]}`;
          } else {
            const extractedDomain = tabUrl.match(/(?:https?:\/\/)?(?:www\.)?([^\/]+)\//);
            if (extractedDomain && extractedDomain[1]) {
              finalLinkedInUrl = extractedDomain[1];
            }
          }
        }

        if (!finalLinkedInUrl) {
          finalLinkedInUrl = tabUrl;
        }

        resolve({
          finalLinkedInUrl,
          isLinkedInUrl: finalLinkedInUrl.includes('linkedin.com'),
        });
      } catch {
        reject('');
      }
    });
  }
}

export function COPY(data: object | unknown) {
  return JSON.parse(JSON.stringify(data));
}

export function goToExternalLink(url: string, isFullUrl: boolean = false) {
  const completeUrl: string = isFullUrl ? url : `https://www.${url}`;
  window.open(completeUrl, '_blank');
}

export function makeAbbreviation(data: string) {
  return data ?
    data.replace(/\s+/g, ' ')
      .trim()
      .split(' ')
      .map((word: string) => (word[0] ?? '').toUpperCase())
      .join('') :
    '';
}
