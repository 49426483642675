<div class="user-feedback">
  <div class="p-details"
       [ngClass]="{'viewed': isContactView, 'disabled': attributeValue === 'Not Available'}"
  >
    <p #text
       [tooltip]="attributeValue === 'Not Available' ?  '' :
           (isContactView ? (text.offsetWidth < text.scrollWidth ?
           attributeValue : '') : ls.data.company.oneViewIsRequire )"
       placement="bottom"
       class="overflow-text"
    >
      {{ attributeValue }}
    </p>

    <div class="actions"
         [ngClass]="{'list-view': isListView}"
    >
      <div class="icon-wrap copy">
        <img src="assets/images/svg/xd-copy-icon.svg"
             alt=""
             class="feedback-icon"
             (click)="copy()"
        >
        <span class="custom-tooltip"
              [innerHTML]="isCopied ? copiedText : copyText"
        ></span>
      </div>
      <div class="icon-wrap thumbs-up">
        <img src="assets/images/svg/xd-thumbs-up-icon.svg"
             alt=""
             class="feedback-icon"
             [tooltip]="ls.data.global.buttons.correct"
             placement="top"
             (click)="userFeedback('correct', attributeName, contactId, attributeValue)"
        >
      </div>
      <div class="icon-wrap thumbs-down">
        <img src="assets/images/svg/xd-thumbs-down-icon.svg"
             alt=""
             class="feedback-icon"
             [tooltip]="ls.data.global.buttons.incorrect"
             placement="top"
             (click)="userFeedback('incorrect', attributeName, contactId, attributeValue)"
        >
      </div>
    </div>
  </div>

  <div class="feedback"
       *ngIf="attributeValue !== 'Not Available' && isContactView && haveFeedback"
       [ngClass]="{'incorrect': !review, 'list-view': isListView}">
    <img class="feedback-icon thumbs-up"
         [src]="review ? 'assets/images/svg/xd-thumbs-up-icon.svg' : 'assets/images/svg/xd-thumbs-down-icon.svg'"
         [alt]="review ? 'thumbs-up-icon' : 'thumbs-down-icon'"
         (click)="userFeedback(userFeedbackRes[0].feedback || 'correct', attributeName, contactId, attributeValue, true)"
    >
  </div>
</div>

<!-- Modal -->
<div class="pop-up" *ngIf="showPopUp">
  <div class="modal-content">
    <h6 class="modal-title">{{ ls.data.global.modal.feedbackHeader }}</h6>
    <div class="modal-desc">
      <p>{{ ls.data.global.modal.feedbackDesc }}</p>
    </div>
    <div class="modal-form">
      <div class="custom-select">
        <div class="selected-option"
             [ngClass]="{'open': openOption}"
             (click)="toggleOptions()"
        >
          {{ feedbackRequest.reason }}
        </div>
        <div class="options"
             [ngClass]="{'open': openOption}"
        >
          <div class="option"
               *ngFor="let reason of reasons"
               (click)="selectReason(reason)"
          >
            {{ reason }}
          </div>
        </div>
      </div>
      <div *ngIf="feedbackRequest.reason === 'Other'"
           class="mb-0"
      >
        <label class="form-label">{{ ls.data.global.misc.feedbackLabel }}</label>
        <textarea class="form-control" rows="2"
                  [placeholder]="ls.data.global.misc.feedbackPlaceholder"
                  [(ngModel)]="feedbackRequest.comment"
        ></textarea>
      </div>
    </div>
    <div class="action-btn">
      <div class="vts-border-theme custom-btn"
           (click)="cancel()"
      >
        {{ ls.data.global.buttons.cancel }}
      </div>
      <div class="vts-theme-bg vts-border-theme custom-btn fill"
           [ngClass]="{'disabled': feedbackRequest.reason === 'Select your reason' ||
              (feedbackRequest.reason === 'Other' && !feedbackRequest.comment)}"
           (click)="submitFeedback()">
        {{ ls.data.global.buttons.submitFeedback }}
      </div>
    </div>
  </div>
</div>
